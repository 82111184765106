





















import {Vue, Component} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSInputField from "@/components/common/TDSInputField.vue";

@Component({
    components: {TDSInputField, TDSButton}
})
export default class NewCustomer extends Vue {
    private email: string = "";
    private emailValid: boolean = true;

    private setMail() {
        if(!this.emailValid) return;
        this.$router.push({path: "/store/new/packages", query: {email: this.email}});
    }
}
