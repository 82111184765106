import _Vue from "vue";

export function LocalStorage(Vue: typeof _Vue): void {

    const allowedVariables: {[key: string]: string} = {"hideJoinerBonus": "boolean", "hideChannelHint": "boolean"};

    Vue.prototype.$storage = {
        set(variable: string, value: string | number | boolean | undefined) {
            if (!Object.keys(allowedVariables).includes(variable)) return;
            localStorage.setItem(variable, String(value));
        },

        get(variable: string) {
            if (!Object.keys(allowedVariables).includes(variable)) return undefined;
            switch (allowedVariables.variable) {
                case "boolean":
                    return Boolean(localStorage.getItem(variable));
                    break;
                case "number":
                    return Number(localStorage.getItem(variable));
                    break;
                default:
                    return localStorage.getItem(variable);
            }
        }
    };
}
