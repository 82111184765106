










































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import {CartItem} from "@/interfaces/entities/CartItem";
import {CartPrice} from "@/interfaces/entities/CartPrice";
import {PaymentMethod} from "@/interfaces/entities/PaymentMethod";
import {SubmitPaymentResponse} from "@/interfaces/httpResponses/SubmitPaymentResponse";
import {SubmitPaymentRequest} from "@/interfaces/httpRequests/SubmitPaymentRequest";
import TDSError from "@/components/common/TDSError.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {PurchaseHistoryEntry, PurchaseHistoryEntryItem} from "@/interfaces/entities/PurchaseHistoryEntry";
import {SubmitCreditPaymentRequest} from "@/interfaces/httpRequests/SubmitCreditPaymentRequest";
import {PaymentDetailsRequest} from "@/interfaces/httpRequests/PaymentDetailsRequest";
import {PaymentDetails} from "@/interfaces/entities/PaymentDetails";

const CustomPaymentOption = () => import(/* webpackChunkName: "custom-payment-option-component" */ "../../theme/" + process.env.VUE_APP_THEME + "/components/partials/CustomPaymentOption/Index.vue");

const purchaseInitialisedAtStoreKey = "purchaseInitialisedAt";
const processingTimeout = 1000 * 30;

@Component({
    components: {DefaultLayout, TDSModal, TDSButton, TDSError, TDSSpinner, CustomPaymentOption}
})
export default class Checkout extends Vue {
    private topUpValue: number = 0;
    private cartPrice: CartPrice | null = null;
    private showOptions: boolean = false;
    private selectedPaymentMethod: PaymentMethod | null = null;
    private paymentMethods: Array<PaymentMethod> = [];
    private paymentDetails: PaymentDetails = {
        price: this.topUpValue, // initialize without vat
        vat: 0,
        totalPrice: this.topUpValue
    };
    private error: string = "";
    private showErrorModal: boolean = false;
    private errorMessage: string = "";
    private errorCountryMissing: boolean = false;
    private isProcessingPayment: boolean = true;
    private timeoutStart: number = 0;
    private showCustomPaymentModal: boolean = false;
    private isSubmittingPayment: boolean = false;
    private paymentSubmitToken: string = "";


    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get submitPaymentRequestBody(): SubmitCreditPaymentRequest {
        if (!this.selectedPaymentMethod) {
            this.error = this.$t("paymentError.paymentMethodMissing").toString();
        }
        return {
            paymentDetails: this.paymentDetails,
            paymentMethodId: this.selectedPaymentMethod?.id ?? ""
        };
    }

    get paymentDetailsRequestBody(): PaymentDetailsRequest {
        return {
            itemPrice: this.topUpValue
        };
    }

    created() {
        this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("checkout.pageTitle");
        this.readValuesFromQuery();
        this.fetchData();
    }

    @Watch("$route")
    routeChanged() {
        this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
    }

    async fetchData() {
        if (!localStorage.topUpValue || !Number(localStorage.topUpValue))
            this.error = this.$t("paymentError.cartIsEmpty").toString();
        this.topUpValue = Number(localStorage.topUpValue);
        this.paymentMethods = await this.$store.dispatch(
            "FETCH_PAYMENT_METHODS",
            [{
                priceValue: this.topUpValue,
                currencyCode: "USD"
            }]
        );
        this.selectedPaymentMethod = this.paymentMethods[0];
        if (this.isProcessingPayment) {
            this.timeoutStart = Date.now();
            this.processPayment();
        }
        try {
            this.paymentDetails = await this.$store.dispatch(
                "FETCH_PAYMENT_DETAILS",
                this.paymentDetailsRequestBody
            );
            // Request was successful
        } catch (error) {
            // Error occurred during the request
            this.showErrorModal = true;
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log("Error status:", error.response.status);
                console.log("Error data:", error.response.data);
                this.errorMessage = error.response.data?.message ?? "";
                if (error.response.status === 412) { // countryMissingException
                    this.errorCountryMissing = true;
                }
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error:", error.message);
            }
        }
    }

    @Watch("$route.params")
    paramsChanged() {
        this.readValuesFromQuery();
    }

    //  After the redirect from the payment service like Stripe or PayPal,
    //  the user lands back on that page. We fetch the payment status from
    //  our server API. During that, the status is: isProcessPayment === true
    private async processPayment() {
        this.$cart.emptyCart();
        const purchaseInitialisedAt = Number(window.localStorage.getItem(purchaseInitialisedAtStoreKey));
        if (!purchaseInitialisedAt) {
            console.error("[Checkout] Payment wasn't initialised by this client!", purchaseInitialisedAt);
            this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
            await this.$router.push({query: {error: "true"}});
            return;
        }
        const purchaseHistory: Array<PurchaseHistoryEntry> = await this.$store.dispatch("FETCH_PURCHASE_HISTORY");
        let isFailedPayment = false;
        const purchaseHappened = purchaseHistory.some((purchaseHistoryEntry: PurchaseHistoryEntry): boolean => {
            return purchaseHistoryEntry.items.some((purchaseHistoryEntryItem: PurchaseHistoryEntryItem): boolean => {
                if (Date.parse(purchaseHistoryEntryItem.dateTime) > purchaseInitialisedAt) {
                    isFailedPayment = purchaseHistoryEntryItem.status === "FAILURE";
                    return ["FAILURE", "SUCCESS"].includes(purchaseHistoryEntryItem.status);
                }
                return false;
            });
        });
        await this.handlePaymentResult(isFailedPayment, purchaseHappened);
    }

    private async handlePaymentResult(isFailedPayment: boolean, purchaseHappened: boolean) {
        if (!purchaseHappened && Date.now() - this.timeoutStart < processingTimeout) {
            setTimeout(() => {
                this.processPayment();
            }, 2000);
        } else if (purchaseHappened && !isFailedPayment) {
            window.localStorage.removeItem(purchaseInitialisedAtStoreKey);
            this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
            await this.$router.push("/credits?success");
        } else if (purchaseHappened && isFailedPayment) {
            window.localStorage.removeItem(purchaseInitialisedAtStoreKey);
            this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
            await this.$router.push({query: {error: "true"}});
        } else { // no purchase happened (or is pending) and timeout exceeded...
            window.localStorage.removeItem(purchaseInitialisedAtStoreKey);
            this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
            await this.$router.push("/credits?pending");
        }
    }

    private readValuesFromQuery() {
        this.showErrorModal = this.$route.query.error === "true";
        this.errorMessage = this.$route.query.message as string ?? "";
        this.isProcessingPayment = this.$route.query["process-payment"] === "true";
    }

    private async submit() {
        this.isSubmittingPayment = true;
        if (this.selectedPaymentMethod?.isCustomPaymentMethod) {
            await this.performCustomPayment();
        } else {
            await this.performWebRedirectPayment();
        }
        this.isSubmittingPayment = false;
    }

    private async performWebRedirectPayment() {
        try {
            const response: SubmitPaymentResponse = await this.$store.dispatch(
                "SUBMIT_PAYMENT",
                this.submitPaymentRequestBody
            );
            console.log("[checkout] Initialised Payment: ", response.data);
            window.localStorage.setItem(purchaseInitialisedAtStoreKey, (Date.now() - 5000) + "");
            window.location.assign(response.data.paymentUrl);
        } catch (e) {
            this.$store.commit("SET_PREVIOUS_VIEW", "/data-packages");
            await this.$router.push({query: {error: "true"}});
        }
    }

    //  This payment method get executed immediately. So on response we know
    //  if the user got the asset or not.
    private async performCustomPayment(token?: string) {
        if (!token) {
            this.showCustomPaymentModal = true;
            return;
        }
        this.showCustomPaymentModal = false;
        this.paymentSubmitToken = token;
        this.isSubmittingPayment = true;
        try {
            window.localStorage.setItem(purchaseInitialisedAtStoreKey, (Date.now() - 5000) + "");
            const response: SubmitPaymentResponse = await this.$store.dispatch(
                "SUBMIT_PAYMENT",
                this.submitPaymentRequestBody
            );
            console.log("[checkout] Submit payment: ", response.data);
            if (response.data.status + "" !== "SUCCESS") {
                this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
                await this.$router.push({query: {error: "true", message: response.data?.message?.content ?? ""}});
            } else {
                await this.$router.push({query: {"process-payment": "true"}});
                this.timeoutStart = Date.now();
                this.processPayment();
            }
        } catch (e) {
            console.error("[Checkout] Error on purchase: ", e);
            this.$store.commit("SET_PREVIOUS_VIEW", "/credits");
            await this.$router.push({query: {error: "true"}});
        }
        this.isSubmittingPayment = false;
    }
}
