




















import {Component, Vue, Watch} from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import BalanceHeader from "@/components/partials/BalanceHeader.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import IosSdk from "@/components/partials/SDKDashboard/IosSdk.vue";
import AndroidSdk from "@/components/partials/SDKDashboard/AndroidSdk.vue";


interface NavOption{
    value: string;
    page: string;
    available: boolean;
}
@Component({
    components: {AndroidSdk, IosSdk, TDSSpinner, TDSModal, TDSButton, Inputfield, DefaultLayout, BalanceHeader}
})
export default class SDKDashboard extends Vue {

    private navOptions: NavOption[] = [
        {value: this.$t("sdkDashboard.navigation.ios") as string, page: "ios-sdk", available: true},
        {value: this.$t("sdkDashboard.navigation.android") as string, page: "android-sdk", available: true}
    ];
    private selectedPage: string = "ios-sdk";


    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        if(!this.$route.params.page) this.$router.push("/sdk/ios-sdk");
        this.pageChanged(this.$route.params);
    }
    private pageChanged(newParams: any) {
        if (!newParams.page || !this.navOptions.find((option: NavOption) => option.page === newParams.page)) return;
        this.selectedPage = newParams.page;
    }

    private showSection(page: string) {
        if (page !== this.selectedPage) {
            this.selectedPage = page;
            this.$router.push("/sdk/" + page);
        }
    }

}
