import axiosInstance from "@/util/axios";
import {GetLegalContentResponse} from "@/interfaces/httpResponses/GetLegalContentResponse";
import {LegalContent} from "@/interfaces/entities/LegalContent";
import {VuexActionContext} from "@/store/index";

export const legalContentActions = {
    async FETCH_TERMS_AND_CONDITIONS(): Promise<LegalContent> {
        const response: GetLegalContentResponse = await axiosInstance.get("/legal-content/dent/terms_and_conditions");
        console.log("[LegalContentStore] Got terms and conditions: ", response.data);
        return response.data;
    },
    async ACCEPT_TERMS_AND_CONDITIONS(context: VuexActionContext<LegalContent>, version: number): Promise<LegalContent> {
        const response: GetLegalContentResponse = await axiosInstance.post("/legal-content/dent/terms_and_conditions/accept", { version });
        console.log("[LegalContentStore] Accepted terms and conditions: ", response.data);
        return response.data;
    },
    async FETCH_PRIVACY_POLICY(): Promise<LegalContent> {
        const response: GetLegalContentResponse = await axiosInstance.get("/legal-content/dent/privacy_policy");
        console.log("[LegalContentStore] Got Privacy Policy: ", response.data);
        return response.data;
    }
};
