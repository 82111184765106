import axios from "@/util/axios";
import {PaymentMethodsResponse} from "@/interfaces/httpResponses/PaymentMethodsResponse";
import {PaymentMethod} from "@/interfaces/entities/PaymentMethod";
import {Price} from "@/interfaces/entities/Price";
import {VuexActionContext} from "@/store/index";
import {SubmitPaymentResponse} from "@/interfaces/httpResponses/SubmitPaymentResponse";
import {SubmitPaymentRequest} from "@/interfaces/httpRequests/SubmitPaymentRequest";
import {GetPurchaseHistoryResponse} from "@/interfaces/httpResponses/GetPurchaseHistoryResponse";
import {PurchaseHistoryEntry} from "@/interfaces/entities/PurchaseHistoryEntry";
import {AddCreditcardResponse} from "@/interfaces/httpResponses/AddCreditcardResponse";
import {SubmitCreditPaymentRequest} from "@/interfaces/httpRequests/SubmitCreditPaymentRequest";
import {PaymentDetails} from "@/interfaces/entities/PaymentDetails";
import {PaymentDetailsResponse} from "@/interfaces/httpResponses/PaymentDetailsResponse";
import {PaymentDetailsRequest} from "@/interfaces/httpRequests/PaymentDetailsRequest";

export interface PaymentsState {
}

export const paymentsState: PaymentsState = {};

export const paymentsMutations = {};

export const paymentsActions = {

    async FETCH_PAYMENT_METHODS(context: VuexActionContext<Array<Price>>, expectedPrices: Array<Price>): Promise<Array<PaymentMethod>> {
        const response: PaymentMethodsResponse = await axios.post("/payments/retrieve-methods", {
            expectedPrices
        });
        console.log("[PaymentsStore] Got Payment methods: ", response.data);
        return response.data.map((paymentMethod: PaymentMethod) => {
            paymentMethod.isCustomPaymentMethod =
                paymentMethod.id === process.env.VUE_APP_CUSTOM_PAYMENT_OPTION_ID;
            return paymentMethod;
        });
    },

    async SUBMIT_PAYMENT(
        context: VuexActionContext<SubmitPaymentRequest>,
        requestBody: SubmitCreditPaymentRequest
    ): Promise<SubmitPaymentResponse> {
        const response: SubmitPaymentResponse = await axios.post(
            "/payments/credit/submit",
            requestBody
        );
        console.log("[PaymentsStore] Submit payment response: ", response.data);
        return response;
    },

    async FETCH_PAYMENT_DETAILS(context: VuexActionContext<PaymentDetailsRequest>, request: PaymentDetailsRequest): Promise<PaymentDetails> {
        const response: PaymentDetailsResponse = await axios.post("/payments/details", request);
        console.log("[PaymentsStore] Got Payment details: ", response.data);
        return response.data;
    },

    async FETCH_PURCHASE_HISTORY(): Promise<Array<PurchaseHistoryEntry>> {
        const response: GetPurchaseHistoryResponse = await axios.get("/payments/history?page_size=9999");
        console.log("[PaymentsStore] Got purchase history: ", response.data);
        return response.data.items;
    },

    async ADD_CREDIT_CARD(): Promise<string> {
        const response: AddCreditcardResponse = await axios.post("/users/current/payment-method");
        return response.data.paymentUrl;
    },

    async DELETE_CREDIT_CARD(): Promise<void> {
        await axios.delete("/users/current/payment-method");
    },

    async FETCH_CREDIT_CARD_INFOS() {
        const response: AddCreditcardResponse = await axios.get("/users/current/payment-method");
        return response.data;
    }
};
