

































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import BalanceHeader from "@/components/partials/BalanceHeader.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { SdkKey } from "@/interfaces/entities/SdkKey";

interface FirstStepsStep {
    name: string;
    input: boolean;
    inputValue?: string;
    inputBig: boolean;
    icon: string;
    readonly: boolean;
    copyToClipboard: boolean;
    buttonText?: string;
    secondButtonText?: string;
    buttonIcon?: string;
    link?: string;
    logo?: string;
    text?: string;
    clickEvent?: string;
    secondClickEvent?: string;
}

@Component({
    components: { TDSSpinner, TDSModal, TDSButton, Inputfield, DefaultLayout, BalanceHeader }
})
export default class AndroidSdk extends Vue {
    private fsModalOpen: boolean = false;
    private submitted: boolean = false;
    private mobileSwitchOpen: boolean = false;
    private selectedChannel: SdkKey = {
        key: "",
        name: "",
        description: "",
        webhookUrl: ""
    };
    private selectedStep: FirstStepsStep = this.steps[0];
    private isLoading: boolean = true;

    get steps(): FirstStepsStep[] {
        return [
            {
                name: "androidEsimAccess",
                input: false,
                inputBig: false,
                readonly: false,
                copyToClipboard: false,
                link: "https://survey.typeform.com/to/MT8yktD2",
                text: this.$t("sdkDashboard.firstSteps.steps.androidEsimAccess.text").toString(),
                icon: ""
            },
            {
                name: "retrieveSdkKey",
                input: true,
                inputValue: this.selectedChannel.key,
                inputBig: false,
                readonly: false,
                copyToClipboard: true,
                buttonText:
                    this.selectedChannel.key === ""
                        ? this.$t("sdkDashboard.firstSteps.steps.retrieveSdkKey.buttonText1").toString()
                        : this.$t("sdkDashboard.firstSteps.steps.retrieveSdkKey.buttonText2").toString(),
                buttonIcon: this.selectedChannel.key === "" ? "lock-open-red.svg" : "copy-red.svg",
                clickEvent: this.selectedChannel.key === "" ? "retrieveSdkKey" : "copySdkKeyToClipboard",
                icon: ""
            },
            {
                name: "getTheSdk",
                input: false,
                inputBig: false,
                readonly: false,
                copyToClipboard: false,
                link: "https://dent-sdk.giga.store/",
                icon: ""
            },
            {
                name: "deviceVerification",
                input: true,
                inputValue: this.selectedChannel.webhookUrl,
                inputBig: false,
                readonly: this.submitted,
                copyToClipboard: false,
                link: "https://dent-sdk.giga.store/webhooks/activationrequest",
                buttonText: !this.submitted
                    ? this.$t("sdkDashboard.firstSteps.steps.deviceVerification.buttonText1").toString()
                    : this.$t("sdkDashboard.firstSteps.steps.deviceVerification.buttonText2").toString(),
                buttonIcon: !this.submitted ? "lock-open-red.svg" : "lock-red.svg",
                secondButtonText: this.submitted ? this.$t("sdkDashboard.firstSteps.steps.deviceVerification.secondButtonText").toString() : undefined,
                clickEvent: !this.submitted ? "submitUrl" : "",
                secondClickEvent: "unsubmitUrl",
                icon: this.submitted ? "lock-red.svg" : ""
            },
            {
                name: "dentBranding",
                input: false,
                inputBig: true,
                readonly: false,
                logo: "powered-by-dent.svg",
                copyToClipboard: true,
                inputValue: this.$t("sdkDashboard.firstSteps.steps.dentBranding.content").toString(),
                icon: ""
            }
        ];
    }

    get channels() {
        return this.$store.state.sdkChannels;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("sdkDashboard.pageTitle");
        this.fetchSdkKeys();
        this.selectedStep = this.steps[0];
    }

    mounted() {
        this.calcTextareaHeight();
    }

    @Watch("isMobile")
    mobileChanged() {
        this.calcTextareaHeight();
    }

    @Watch("selectedStep")
    selectedStepChanged() {
        this.$nextTick(() => {
            this.calcTextareaHeight();
        });
    }

    @Watch("fsModalOpen")
    fsModalOpenChanged(value: boolean) {
        this.steps;
        if (value) {
            this.$nextTick(() => {
                this.calcTextareaHeight();
            });
        }
        if (this.selectedChannel.webhookUrl && !this.fsModalOpen) {
            this.submitted = true;
        }
        if (!this.fsModalOpen) {
            this.steps[3].inputValue = this.selectedChannel.webhookUrl;
        }
        if (this.fsModalOpen) {
            this.selectedStep = this.steps[this.steps.findIndex((step: FirstStepsStep) => step.name === this.selectedStep.name)];
        }
    }

    private async fetchSdkKeys() {
        this.isLoading = true;
        try {
            await this.$store.dispatch("FETCH_SDK_KEYS");
            this.selectedChannel = this.channels.length > 0 ? this.channels[0] : [];
            await this.$store.dispatch("FETCH_WEBHOOK_URL", this.selectedChannel.key);
            if (this.selectedChannel.webhookUrl) this.submitted = true;
        } catch (e) {
            console.log("[SDK Dashboard] Failed fetching SDK keys");
        } finally {
            this.isLoading = false;
        }
    }

    private calcTextareaHeight() {
        const textarea = this.$refs.request as HTMLTextAreaElement;
        if (!textarea) return;
        const lines = textarea.value.split(/\n/g) || [];
        const maxChars = this.isMobile ? 33 : 100;
        let numberOfLines = 0;
        lines.forEach((line: string) => {
            numberOfLines += Math.ceil(line.length / maxChars);
        });
        textarea.style.height = (this.isMobile ? numberOfLines * 16 + 70 : numberOfLines * 14 + 46) + "px";
    }

    private copyRequest() {
        (this.$refs.request as HTMLTextAreaElement).select();
        document.execCommand("copy");
        this.$toastr.success(this.$t("components.inputfield.copied"), true, true);
    }

    private async copyToClipBoard(text: string) {
        if (!text) return;
        await navigator.clipboard.writeText(text);
        this.$toastr.success(this.$t("components.inputfield.copied"), true, true);
    }

    private nextStep() {
        this.selectedStep = this.steps[this.steps.findIndex((step: FirstStepsStep) => step.name === this.selectedStep.name) + 1];
    }

    private prevStep() {
        this.selectedStep = this.steps[this.steps.findIndex((step: FirstStepsStep) => step.name === this.selectedStep.name) - 1];
    }

    private async retrieveSdkKey() {
        if (!this.selectedChannel) await this.fetchSdkKeys();
        this.selectedChannel = this.channels.length > 0 ? this.channels[0] : [];
        this.selectedStep = this.steps[1];
    }

    private async submitUrl() {
        if (this.selectedStep.inputValue && !(this.$refs.urlInput as Inputfield)?.submit()) return;
        try {
            await this.$store.dispatch("SAVE_WEBHOOK_URL", {
                url: this.selectedStep.inputValue,
                key: this.selectedChannel.key
            });
            if (this.selectedStep.inputValue) this.submitted = true;
            this.selectedChannel.webhookUrl = this.selectedStep.inputValue;
            this.steps[3].inputValue = this.selectedStep.inputValue;
            this.selectedStep = this.steps[3];
        } catch (e) {
            this.$toastr.error(this.$t("sdkDashboard.firstSteps.steps.deviceVerification.error").toString());
        }
    }

    private unsubmitUrl() {
        this.submitted = false;
        this.selectedStep = this.steps[3];
    }

    private openDeviceVerificationStep() {
        this.submitted = false;
        this.selectedStep = this.steps[3];
        this.fsModalOpen = true;
    }

    private handleButtonClick(functionName: string) {
        switch (functionName) {
            case "retrieveSdkKey":
                this.retrieveSdkKey();
                break;
            case "copyRequest":
                this.copyRequest();
                break;
            case "copySdkKeyToClipboard":
                this.copyToClipBoard(this.selectedChannel.key);
                break;
            case "submitUrl":
                this.submitUrl();
                break;
            case "unsubmitUrl":
                this.unsubmitUrl();
                break;
            default:
                return;
        }
    }
}
