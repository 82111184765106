









































import {Component, Prop, Vue} from "vue-property-decorator";
import TDSModal from "../../../../../components/partials/TDSModal/TDSModal.vue";
import Inputfield from "../../../../../components/partials/Inputfield.vue";
import TDSButton from "../../../../../components/common/TDSButton.vue";

@Component({
    components: {TDSModal, Inputfield, TDSButton}
})
export default class CustomPaymentOption extends Vue {
    private smsCode: string = "";

    @Prop({ type: Boolean, default: false })
    private inProgress!: boolean;

    smsCodeValidator(smsCode: string) {
        return smsCode && smsCode.replace(/\D/g, "") === smsCode;
    }

    get inputValid() {
        return this.smsCode;
    }

    login() {
        if (!(this.$refs.smsCodeInput as Inputfield).submit()) {
            return console.log("[DENTPayment] smsCode invalid");
        }
        this.$emit("next", this.smsCode);
    }

}
