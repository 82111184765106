




































import {Component, Prop, Vue} from "vue-property-decorator";
import {SoldItem} from "@/interfaces/entities/SoldItem";
import {Price} from "@/interfaces/entities/Price";
import {emojiFlag} from "@/util/emojiFlag";

interface SSoldItem {
    retailPriceEdited: boolean;
    name: string;
    sizeValue: number;
    sizeUnit: string;
    validitySize:  number;
    validityUnit: string;
    prices: Array<Price>;
    retailPrices: Array<Price>;
}

@Component({})
export default class CardContent extends Vue {
    @Prop({required: true}) soldItem!: SoldItem | SSoldItem;
    @Prop({type: String}) type!: string;

    private getEmojiFlag(countryCode: string): string {
        return emojiFlag(countryCode, "🌏");
    }
}
