













































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

interface Column {
  label: string;
  sortable?: boolean;
  sortBy?: string;
  align?: string;
  width?: string;
}

@Component
    export default class TableList extends Vue {
  @Prop() rows!: any;
  @Prop() groupedRows!: any;
  @Prop() groupedKey!: string;
  @Prop() additionalRowProperty!: string;
  @Prop() itemKey!: string;
  @Prop() columns!: {[key: string]: Column};
  @Prop({type: Boolean, default: false}) isClickable!: boolean;
  @Prop() rowHeight!: string;
  @Prop({type: Function}) groupedBy!: any;
  @Prop({type: String}) emptyText!: string;

  private currentSort: string = "";
  private currentSortDir: string = "ASC";
  private activeGroups: string[] = [];

  created() {
    const key = this.$route.query.sortKey;
    if (key) {
      this.currentSort = key as string;
    }
    const dir = this.$route.query.sortDir;
    if (dir) {
      this.currentSortDir = dir as string;
    }
  }

  sort(key: string) {
    if (key === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "ASC" ? "DESC" : "ASC";
    }
    this.currentSort = key;
    this.$router.push({query:{sortDir: this.currentSortDir, sortKey: this.currentSort}});
  }

  @Watch("sortedRows")
  sortedRowsChanged() {
      if(this.groupedRows && this.groupedRows.length > 0) {
          this.$nextTick(() => {
              this.activeGroups.forEach((group: string) => {
                  // @ts-ignore
                  this.$refs["groupedRow"+group][0].style.height = this.$refs["groupedRow"+group][0].children.length * 48 + "px";
              });
          });
      }
  }

  get sortedRows(): any {
    // if(this.groupedBy && typeof this.groupedBy === "function") {
    //     const groupedRows = {};
    //     this.rows.forEach((item: { [key: string]: string }) => {
    //         const key = this.groupedBy(item);
    //         // eslint-disable-next-line no-prototype-builtins
    //         if(!groupedRows.hasOwnProperty(key)) groupedRows[key] = [];
    //         groupedRows[key].push(item);
    //     });
    //     console.log("groupedRows", groupedRows);
    //     return groupedRows;
    // }
    let rows: any = this.rows;
      if(this.groupedRows && this.groupedRows.length > 0) rows = this.groupedRows.map((row: any) => {
          row.groupedKey = row[this.groupedKey];
          return row;
      });
    if (this.currentSort) {
        let sorting: any = this.currentSort;
        if (this.columns[sorting].sortBy) sorting = this.columns[sorting].sortBy;
        return rows.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
            if(typeof sorting === "function") return this.getSortIndex(sorting(a), sorting(b));
            else return this.getSortIndex(a[this.currentSort], b[this.currentSort]);
        });
    } else return rows;
  }

  get isMobile() {
    return this.$store.state.isMobile;
  }

  private getSortIndex(a: string | number, b: string | number) {
      const sortIsAscending = this.currentSortDir === "ASC";
      if(typeof a === "string" && typeof b === "string") {
          a = a.toLowerCase();
          b = b.toLowerCase();
      }
      if (a < b) {
          return sortIsAscending ? -1 : 1;
      }
      if (a > b) {
          return sortIsAscending ? 1 : -1;
      }
      return 0;
  }

  private flexAlign(align: string): string {
    switch(align) {
      case "left": return "flex-start";
      case "center": return "center";
      case "right": return "flex-end";
      default: return align;
    }
  }

  private toggleOpen(key: any) {
     if(!this.activeGroups.includes(key)) this.activeGroups.push(key);
     else this.activeGroups.splice(this.activeGroups.indexOf(key), 1);
  }

  private beforeEnter(el: any) {
      el.style.height = "0";
  }
  private enter(el: any) {
      el.style.height = el.scrollHeight + "px";
  }
  private beforeLeave(el: any) {
      el.style.height = el.scrollHeight + "px";
  }
  private leave(el: any) {
      el.style.height = "0";
  }
}

