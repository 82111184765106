
















import {Component, Prop, Vue} from "vue-property-decorator";
import TDSDate from "@/util/TDSDate";

@Component
export default class DefaultFooter extends Vue {
    @Prop({type: Boolean, default: false}) darkMode!: boolean;

    get appVersion(): string {
        return process.env.VUE_APP_VERSION + "";
    }

    get gitCommitHash(): string {
        return process.env.VUE_APP_GIT_COMMIT_HASH + "";
    }

    get mode(): string {
        if(process.env.NODE_ENV === "development") return process.env.NODE_ENV.toString();
        else if(process.env.VUE_APP_AUTH_API_URL?.includes("llamaapi")) return "staging";
        else if(process.env.VUE_APP_AUTH_API_URL?.includes("dromedaryapi")) return "reference";
        else return "";
    }

    get apiVersion(): string {
        return this.$store.state.apiVersion;
    }

    get currentYear(): string {
        return new TDSDate().format("YYYY");
    }

    created() {
        this.$store.dispatch("FETCH_API_VERSION");
    }
}
