


















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import { User } from "@/interfaces/entities/User";
import CountryPicker from "@/components/partials/CountryPicker.vue";
import { Country } from "@/interfaces/Country";
import countryHelper from "@/util/countries";

@Component({
    components: { Inputfield, TDSModal, TDSButton, DefaultLayout, CountryPicker }
})
export default class AccountDetails extends Vue {
    @Prop() userInformations!: User;
    private firstName: string = "";
    private lastName: string = "";
    private companyName: string = "";
    private taxId: string = "";
    private showModal: boolean = false;
    private updatingUser: boolean = false;
    private addressLine1: string = "";
    private addressLine2: string = "";
    private addressLine3: string = "";
    private postalCode: string = "";
    private townOrDistrict: string = "";
    private selectedCountry: Country | null = null;
    private countryValid: boolean = true;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get countryName(): string {
        if (this.userInformations.addresses[0].country != undefined) return countryHelper.getCountryName(this.userInformations.addresses[0].country);
        else return "";
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("account.details.pageTitle");
    }

    incorrectCountry(input: string) {
        this.countryValid = false;
    }

    selectedCountryChanged(newSelectedCountry: Country) {
        this.selectedCountry = newSelectedCountry;
        this.countryValid = true;
    }

    private showUpdateModal() {
        this.firstName = this.userInformations.firstName;
        this.lastName = this.userInformations.lastName;
        this.companyName = this.userInformations.companyName;
        this.taxId = this.userInformations.taxId;
        if (this.userInformations.addresses[0] != undefined) {
            this.addressLine1 = this.userInformations.addresses[0].addressLine1;
            this.addressLine2 = this.userInformations.addresses[0].addressLine2;
            this.addressLine3 = this.userInformations.addresses[0].addressLine3;
            this.postalCode = this.userInformations.addresses[0].postalCode;
            this.townOrDistrict = this.userInformations.addresses[0].townOrDistrict;
        }
        this.showModal = true;
    }

    private async updateUser() {
        if (this.updatingUser) return;
        this.updatingUser = true;
        try {
            const response = await this.$store.dispatch("UPDATE_ACCOUNT", {
                firstName: this.firstName,
                lastName: this.lastName,
                companyName: this.companyName,
                taxId: this.taxId,
                address: {
                    addressLine1: this.addressLine1,
                    addressLine2: this.addressLine2,
                    addressLine3: this.addressLine3,
                    townOrDistrict: this.townOrDistrict,
                    country: this.selectedCountry?.code ? this.selectedCountry?.code : "",
                    postalCode: this.postalCode,
                    addressType: "BILLING"
                }
            });
            if (response.data) this.$emit("userInformationsUpdated", response.data);
            this.showModal = false;
        } catch (e) {
            if (e.response && e.response.data && e.response.data.message) this.$toastr.error(e.response.data.message);
            else this.$toastr.error("Failed updating personal information");
        } finally {
            this.updatingUser = false;
        }
    }
}
