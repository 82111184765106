export interface WebhookUrl{
    type: WEBHOOK_TYPES;
    url: string;
}

export enum WEBHOOK_TYPES {
    BALANCE_ALERT = "Balance",
    ESIM_STATUS = "EsimStatus",
    CUSTOMER_REGISTRATION = "SdkActivation",
    COUNTRY_CHANGE = "CountryChange"
}
