







































import {Component, Prop, Vue} from "vue-property-decorator";
    import DefaultNavigationBar from "../partials/DefaultNavigationBar.vue";
    import DefaultFooter from "../partials/DefaultFooter.vue";
    import DefaultHeader from "@/components/partials/DefaultHeader.vue";
import {LegalContent} from "@/interfaces/entities/LegalContent";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
  components: {
      TDSButton,
    DefaultHeader,
    DefaultFooter,
    DefaultNavigationBar
  }
})
    export default class DefaultLayout extends Vue {
        @Prop({type: Boolean, default: true}) showNav!: boolean;
        @Prop({type: Boolean, default: true}) showFooter!: boolean;
        private tcs: LegalContent | null = null;
        private showTCHint: boolean = true;
        private isLoading: boolean = false;

        get previousView(): boolean {
            return this.$store.state.previousView;
        }

        get isMobile(): boolean {
            return this.$store.state.isMobile;
        }

        get headerHeight(): string {
            return this.$store.state.headerHeight;
        }

        get authenticated(): boolean {
            return !!this.$store.state.auth;
        }

        created() {
            this.getTandCs();
        }

        async getTandCs() {
            this.tcs = await this.$store.dispatch("FETCH_TERMS_AND_CONDITIONS");
        }

        async acceptTandC() {
            if(!this.tcs) return;
            this.isLoading = true;
            try {
                this.tcs = await this.$store.dispatch("ACCEPT_TERMS_AND_CONDITIONS", this.tcs.currentVersion);
            }catch (e) {
                this.$toastr.error(this.$t("tandc.error"));
                console.error("[DefaultLayout] Failed accepting T&Cs");
            } finally {
                this.isLoading = false;
            }
        }
    }
