






import {Component, Vue, Watch} from "vue-property-decorator";

@Component
export default class App extends Vue {

    get authenticated(): boolean {
        return !!this.$store.state.auth;
    }

    @Watch("authenticated")
    authChanged(newVal: boolean) {
        if (newVal) {
            this.loadSurveyScript();
        }
    }

    /**
     * Load Survey Script, corresponding div-tag is in index.html
     */
    loadSurveyScript() {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://embed.typeform.com/next/embed.js";
        head.appendChild(script);
    }

    mounted() {
        this.$store.commit("SET_IS_MOBILE", (document.documentElement.clientWidth <= 920));
        window.addEventListener("resize", this.handleResize);
        if (this.authenticated) {
            this.loadSurveyScript();
        }
    }

    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    }

    public handleResize() {
        this.$store.commit("SET_IS_MOBILE", (document.documentElement.clientWidth <= 920));
    }
}
