import axios from "@/util/axios";
import {VuexActionContext} from "@/store/index";
import {GetAvailableProductsResponse} from "@/interfaces/httpResponses/GetAvailableProductsResponse";
import {Product} from "@/interfaces/entities/Product";
import {CartItem} from "@/interfaces/entities/CartItem";
import {CartPrice} from "@/interfaces/entities/CartPrice";
import {CartPriceResponse} from "@/interfaces/httpResponses/CartPriceResponse";
import {GetJoinerBonusInfo} from "@/interfaces/httpResponses/GetJoinerBonusInfo";
import {JoinerBonusInfo} from "@/interfaces/entities/JoinerBonusInfo";
import {BulkDiscountInfo} from "@/interfaces/entities/BulkDiscount";
import {GetBulkDiscountInfo} from "@/interfaces/httpResponses/GetBulkDiscountInfo";
import {Price} from "@/interfaces/entities/Price";

export interface ProductsState {
    availableProducts: Array<Product>;
    discountRate: number;
}

export const productsState: ProductsState = {
    availableProducts: [],
    discountRate: 0
};

export const productsMutations = {
    SET_AVAILABLE_PRODUCTS(state: ProductsState, products: Array<Product>) {
        state.availableProducts = products;
    },

    SET_DISCOUNT_RATE(state: ProductsState, rate: number) {
        state.discountRate = rate;
    }
};

export const productsActions = {

    async FETCH_JOINER_BONUS_INFO(): Promise<JoinerBonusInfo> {
        const response: GetJoinerBonusInfo = await axios.get("/products/joiner-bonus");
        console.log("[productsStore] Got joiner bonus: ", response.data);
        return response.data;
    },

    async FETCH_BULK_DISCOUNT_INFOS(): Promise<Array<BulkDiscountInfo>> {
        const response: GetBulkDiscountInfo = await axios.get("/payments/bulk-discounts");
        console.log("[productsStore] Got joiner bonus: ", response.data);
        return response.data;
    },

    async FETCH_AVAILABLE_PRODUCTS({commit}: VuexActionContext<Array<Product> | number>) {
        const response: GetAvailableProductsResponse = await axios.get("/products/data-packages");
        console.log("[productsStore] Got products: ", response.data);
        commit("SET_AVAILABLE_PRODUCTS", response.data.dataPackages.map((p: Product) => {
            p.color = "black"; // TODO: replace me!!!
            return p;
        }));
        commit("SET_DISCOUNT_RATE", response.data.discountRate);

    },

    async CALCULATE_CART_PRICE(context: VuexActionContext<Array<Price>>, cartItems: Array<CartItem>): Promise<CartPrice> {
        const requestBody = cartItems.map((c: CartItem) => {
            return {
                dataPackageId: c.id,
                amount: c.amount
            };
        });
        const response: CartPriceResponse = await axios.post("/products/calculate-cart-price", requestBody);
        return response.data;
    }
};
