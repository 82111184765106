









import {Component, Vue, Watch} from "vue-property-decorator";
import StoreLayout from "../layouts/StoreLayout.vue";
import NewCustomer from "@/components/partials/POSStore/NewCustomer.vue";
import ExistingCustomer from "@/components/partials/POSStore/ExistingCustomer.vue";

@Component({
    components: {ExistingCustomer, NewCustomer, StoreLayout}
})
export default class POSStore extends Vue {
    private validTypes: Array<string> = ["new", "existing", "guest"];

    created() {
        if(!this.$route.params.type || !this.validTypes.includes(this.$route.params.type)) this.$router.replace("/store");
        else if(this.$route.params.type === "guest") this.$router.replace("/store/guest/packages");
    }

    @Watch("$route.params.type")
    paramsChanged(value?: string) {
        if(!value || !this.validTypes.includes(value)) this.$router.replace("/store");
        else if(value === "guest") this.$router.replace("/store/guest/packages");
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }
}
