





































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ImgText extends Vue {
    @Prop({required: true}) vid!: string;
    @Prop() headline!: string;
    @Prop() text!: string;
    @Prop() internalLink!: string;
    @Prop() externalLink!: string;
    @Prop() linkText!: string;
    @Prop({type: Boolean, default: false}) reverse!: boolean;

    get authenticated(): boolean {
        return !!this.$store.state.auth;
    }

    showLogin() {
        this.$authenticator.signIn();
    }
}
