





























































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import TableList from "@/components/partials/TableList.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import SoldItemCardContent from "@/components/partials/YourStore/SoldItemCardContent.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { emojiFlag } from "@/util/emojiFlag";
import TDSSelect from "@/components/common/TDSSelect.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import TDSInputField from "@/components/common/TDSInputField.vue";
import { CreditBalance } from "@/interfaces/entities/CreditBalance";
import { CreditCard } from "@/interfaces/entities/CreditCard";

interface RouteParams {
    [key: string]: string;
}

@Component({
    components: {
        TDSInputField,
        FilterPanel,
        TDSSelect,
        TDSSpinner,
        TableList,
        TDSModal,
        TDSButton,
        Inputfield,
        SoldItemCardContent
    }
})
export default class Inventory extends Vue {
    public creditBalance: CreditBalance = {
        value: 0,
        currency: "USD"
    };
    public creditCardInfo: CreditCard = {
        expiry: "",
        last4: ""
    };
    public autoTopUpActive: boolean = false;
    public showManualTopUpModal: boolean = false;
    public showAutoTopUpModal: boolean = false;
    public showDeleteCreditCardModal: boolean = false;
    public autoTopUpToast: string = "";
    public statusType: string = "";
    public statusModal: string | (string | null)[] | null = null;
    public isLoadingAddCreditCard: boolean = false;
    public isLoadingDeleteCreditCard: boolean = false;
    public manualCredit: string = "";
    public manualCreditRegex: RegExp = new RegExp(/^0*([1-9]\d{2,}|[1-9]\d)$/);
    public manualCreditValid: boolean = true;
    public autoThreshold: string = "";
    public autoThresholdRegex: RegExp = new RegExp(/^0*([1-9]\d{2,}|[1-9]\d)$/);
    public autoThresholdValid: boolean = true;
    public autoAmount: string = "";
    public autoAmountRegex: RegExp = new RegExp(/^0*([1-9]\d{2,}|[2-9]\d)$/);
    public autoAmountValid: boolean = true;
    private id: string = "";
    private isLoadingAutoSetup: boolean = false;
    private isLoading: boolean = false;

    get isMobile() {
        return this.$store.state.isMobile;
    }

    get hasCreditCard() {
        return Boolean(this.creditCardInfo.expiry && this.creditCardInfo.last4);
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("yourStore.inventory.pageTitle");
        this.statusModal = this.$route.query?.status || null;
        if (!this.statusModal) localStorage.removeItem("inventoryStatus");
        this.getStatusType();
        this.fetchData();
    }

    @Watch("$route.query")
    queryChanged() {
        this.statusModal = this.$route.query.status || null;
    }

    private getEmojiFlag(countryCode: string): string {
        return emojiFlag(countryCode, "🌏");
    }

    private async fetchData() {
        this.isLoading = true;
        try {
            this.creditCardInfo = await this.$store.dispatch("FETCH_CREDIT_CARD_INFOS");
            const response = await this.$store.dispatch("FETCH_CREDIT_INFOS");
            this.creditBalance = response?.balance;
            this.autoTopUpActive = response?.autoTopUpEnabled || false;
            this.autoThreshold = this.autoTopUpActive && response?.topUpThreshold ? String(response.topUpThreshold) : "";
            this.autoAmount = this.autoTopUpActive && response?.topUpAmount ? String(response.topUpAmount) : "";
        } catch (e) {
            console.error("[Your Store Inventory] Failed fetching inventory items");
        } finally {
            this.isLoading = false;
        }
    }

    closeManualTopUpModal() {
        this.manualCredit = "";
        this.showManualTopUpModal = false;
    }

    public manualTopUp() {
        if (!this.manualCredit || !this.manualCreditValid) return;
        localStorage.inventoryStatus = "topup";
        localStorage.topUpValue = this.manualCredit;
        this.$router.push("/add-credits/checkout");
    }

    resetAutoTopUpModal() {
        this.autoThreshold = "";
        this.autoAmount = "";
        this.showAutoTopUpModal = true;
    }

    public async setAutoTopUp() {
        if (this.isLoadingAutoSetup || !this.autoThreshold || !this.autoAmount || !this.autoThresholdValid || !this.autoAmountValid) return;
        this.isLoadingAutoSetup = true;
        try {
            await this.$store.dispatch("SET_AUTO_TOPUP", {
                enabled: true,
                threshold: this.autoThreshold,
                amount: this.autoAmount
            });
            if (this.autoTopUpActive) this.autoTopUpToast = "updated";
            else this.autoTopUpToast = "activated";
            this.autoTopUpActive = true;
        } catch (e) {
            this.autoTopUpToast = "error";
        } finally {
            this.isLoadingAutoSetup = false;
            this.showAutoTopUpModal = false;
        }
    }

    public async unsetAutoTopUp() {
        if (this.isLoadingAutoSetup) return;
        this.isLoadingAutoSetup = true;
        try {
            await this.$store.dispatch("SET_AUTO_TOPUP", {
                enabled: false
            });
            this.autoTopUpToast = "deactivated";
            this.autoTopUpActive = false;
        } catch (e) {
            this.autoTopUpToast = "error";
        } finally {
            this.isLoadingAutoSetup = false;
            this.showAutoTopUpModal = false;
        }
    }

    private getStatusType() {
        this.statusType = localStorage.inventoryStatus || "";
    }

    public async addCreditCard() {
        if (this.isLoadingAddCreditCard) return;
        this.isLoadingAddCreditCard = true;
        const url = await this.$store.dispatch("ADD_CREDIT_CARD");
        localStorage.inventoryStatus = "paymentMethod";
        location.href = url;
        this.isLoadingAddCreditCard = false;
    }

    public async deleteCreditCard() {
        if (this.isLoadingDeleteCreditCard) return;
        this.isLoadingDeleteCreditCard = true;
        try {
            await this.$store.dispatch("DELETE_CREDIT_CARD");
            this.creditCardInfo = await this.$store.dispatch("FETCH_CREDIT_CARD_INFOS");
            this.showDeleteCreditCardModal = false;
        } catch (e) {
            console.error("[Inventory] Error deleting credit card: ", e);
            this.$toastr.error("Failed deleting your credit card.");
        } finally {
            this.isLoadingDeleteCreditCard = false;
        }
    }
}
