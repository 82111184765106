















































































import { Component, Prop, Vue } from "vue-property-decorator";
import TDSModal from "../../components/partials/TDSModal/TDSModal.vue";
import TDSButton from "../../components/common/TDSButton.vue";
import TDSInputField from "@/components/common/TDSInputField.vue";
import TableList from "@/components/partials/TableList.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { EsimDetails } from "@/interfaces/entities/EsimDetails";
import countryHelper from "@/util/countries";

@Component({
    components: { TDSSpinner, TableList, TDSModal, TDSInputField, TDSButton }
})
export default class ESimDetailsModal extends Vue {
    @Prop({ required: true }) esimIccid!: string;
    @Prop({ required: true }) email!: string;

    private eSimDetails: EsimDetails | null = null;
    private tableColumnsDetails: object = {
        email: { label: this.$t("tableHeader.email") as string },
        iccid: { label: "ICCID" as string },
        status: { label: this.$t("tableHeader.status") as string }
    };
    private tableColumnsLast: object = {
        lastConnection: { label: this.$t("yourStore.history.details.eSim.lastConnection") as string },
        lastDataConsumed: { label: this.$t("yourStore.history.details.eSim.lastDataConsumed") as string, width: "155px" },
        lastCountry: { label: this.$t("yourStore.history.details.eSim.lastCountry") as string },
        lastNetwork: { label: this.$t("yourStore.history.details.eSim.lastNetwork") as string }
    };

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        this.getESimDetails();
    }

    private async getESimDetails() {
        this.eSimDetails = await this.$store.dispatch("FETCH_ESIM_DETAILS", this.esimIccid);
    }

    private closeModal() {
        this.$emit("close");
    }

    private getCountryName(code: string) {
        return countryHelper.getCountryName(code);
    }
}
