

















import { Component, Vue } from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";

interface ToastType {
    text: string;
    type: string;
    subtle: boolean;
}

@Component({
    components: { TDSButton }
})
export default class Toast extends Vue {
    private toast!: ToastType;
    private show: boolean = false;
    private autoHide: boolean = false;
    private duration: number = 3000;

    mounted() {
        this.$nextTick(() => {
            this.show = true;
            if (this.autoHide) setTimeout(() => this.closeToast(), this.duration);
        });
    }

    private closeToast() {
        this.show = false;
        setTimeout(() => {
            this.$emit("close");
        }, 500);
    }

    private toastClicked() {
        this.$emit("clickedToast");
    }
}
