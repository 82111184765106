import Vue from "vue";
import store from "../store";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { authenticator } from "@/util/authenticator";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/data-packages",
        name: "Data plans",
        component: () => import(/* webpackChunkName: "data-packages" */ "../components/pages/AddPackages.vue")
    },
    {
        path: "/add-credits/checkout",
        name: "Add Credits Checkout",
        component: () => import(/* webpackChunkName: "add-credits-checkout" */ "../components/pages/ManualCheckout.vue")
    },
    {
        path: "/data-packages/checkout",
        name: "Checkout",
        component: () => import(/* webpackChunkName: "checkout" */ "../components/pages/Checkout.vue")
    },
    {
        path: "/data-packages/checkout/success",
        name: "Checkout Success",
        component: () => import(/* webpackChunkName: "checkout-success" */ "../components/pages/CheckoutSuccess.vue")
    },
    {
        path: "/your-store",
        name: "Your Store",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "your-store" */ "../components/pages/YourStore.vue")
    },
    {
        path: "/your-store/:page",
        name: "Your Store Page",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "your-store-page" */ "../components/pages/YourStore.vue")
    },
    {
        path: "/your-store/history/:id",
        name: "Your Store History ID",
        meta: { headerHeight: "160px" },
        component: () => import(/* webpackChunkName: "your-store-history-id" */ "../components/pages/HistoryDetails.vue")
    },
    {
        path: "/your-store/:page/:id",
        name: "Your Store Page ID",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "your-store-page-id" */ "../components/pages/YourStore.vue")
    },
    {
        path: "/store",
        meta: { headerHeight: "160px" },
        name: "POS Store",
        component: () => import(/* webpackChunkName: "pos-store" */ "../components/pages/POSStore.vue")
    },
    {
        path: "/store/:type",
        meta: { headerHeight: "160px" },
        name: "POS Store Customer",
        component: () => import(/* webpackChunkName: "pos-store" */ "../components/pages/POSStoreCustomer.vue")
    },
    {
        path: "/store/:type/packages",
        meta: { headerHeight: "160px" },
        name: "POS Store Package",
        component: () => import(/* webpackChunkName: "pos-store" */ "../components/pages/POSStoreProducts.vue")
    },
    {
        path: "/store/:type/packages/:id",
        meta: { headerHeight: "160px" },
        name: "POS Store Package Sell",
        component: () => import(/* webpackChunkName: "pos-store" */ "../components/pages/POSStorePackage.vue")
    },
    {
        path: "/api",
        name: "API",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "api" */ "../components/pages/APIDashboard.vue")
    },
    {
        path: "/api/specifications",
        name: "API Specifications",
        meta: { public: true, headerHeight: "173px" },
        component: () => import(/* webpackChunkName: "api-specifications" */ "../components/pages/APISpecifications.vue")
    },
    {
        path: "/sdk",
        name: "SDK",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "sdk" */ "../components/pages/SDKDashboard.vue")
    },
    {
        path: "/sdk/:page",
        name: "SDK Page",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "your-store-page" */ "../components/pages/SDKDashboard.vue")
    },
    {
        path: "/credits",
        name: "Credit Page",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "credits-page" */ "../components/pages/CreditsPage.vue")
    },
    {
        path: "/pricing",
        name: "Pricing Page",
        meta: { headerHeight: "213px" },
        component: () => import(/* webpackChunkName: "pricing-page" */ "../components/pages/PricingPage.vue")
    },
    {
        path: "/esim/profile",
        name: "ESIM Installation",
        meta: { public: true },
        component: () => import(/* webpackChunkName: "esim-installation" */ "../components/pages/ESIMInstallation.vue")
    },
    {
        path: "/customer/profile",
        name: "Customer Profile",
        meta: { public: true, headerHeight: "160px" },
        component: () => import(/* webpackChunkName: "customer-profile" */ "../components/pages/CustomerProfile.vue")
    },
    {
        path: "/account",
        name: "Account",
        component: () => import(/* webpackChunkName: "account" */ "../components/pages/Account.vue")
    },
    {
        path: "/account/:page",
        name: "Account Page",
        component: () => import(/* webpackChunkName: "account" */ "../components/pages/Account.vue")
    },
    {
        path: "/account/:page/:id",
        name: "Account Page Detail",
        component: () => import(/* webpackChunkName: "account" */ "../components/pages/Account.vue")
    },
    {
        path: "/terms-and-conditions",
        name: "Terms & Conditions",
        meta: { public: true },
        component: () => import(/* webpackChunkName: "tandc" */ "../components/pages/TermsAndConditionsPage.vue")
    },
    {
        path: "/privacy-policy",
        name: "Privacy Policy",
        meta: { public: true },
        component: () => import(/* webpackChunkName: "privacy" */ "../components/pages/PrivacyPage.vue")
    },
    {
        path: "*",
        name: "Landingpage",
        meta: { public: true },
        component: () => import(/* webpackChunkName: "landingpage" */ "../theme/" + process.env.VUE_APP_THEME + "/components/pages/LandingPage.vue")
    }
];

const router = new VueRouter({
    routes
});
declare let gtag: any;
declare let gaMeasurementId: string;

function sendGoogleAnalyticsEvent(route: Route) {
    gtag("event", "page_view", {
        page_title: route.name,
        page_location: "Dent Gigastore",
        page_path: route.path,
        send_to: gaMeasurementId
    });
}

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    sendGoogleAnalyticsEvent(to);
    window.scrollTo(0, 0);
    if (!to.meta.public) {
        authenticator
            .isAuthenticated()
            .then((authenticated: boolean) => {
                if (authenticated) {
                    next();
                } else {
                    next("/");
                }
            })
            .catch(() => {
                next("/");
            });
    } else {
        if (to.name === "Landingpage") {
            authenticator
                .isAuthenticated()
                .then((authenticated: boolean) => {
                    if (authenticated) {
                        next("/your-store");
                    } else {
                        next();
                    }
                })
                .catch(() => {
                    next();
                });
        } else {
            next();
        }
    }
});

router.afterEach((to: Route, from: Route) => {
    store.commit("SET_PREVIOUS_VIEW", "");
    store.commit("SET_HEADER_HEIGHT", to.meta?.headerHeight ?? "213px");
    document.body.style.position = "relative";
    if (to.path !== from.path) document.title = process.env.VUE_APP_THEME_TITLE || "";
});

export default router;
