

























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import countryHelper from "../../util/countries";
import {Country} from "@/interfaces/Country";
import {Region} from "@/store/countriesStore";
import Inputfield from "@/components/partials/Inputfield.vue";

@Component({
    components: {Inputfield}
})


export default class CountryPicker extends Vue {

    @Prop({type: String, default: ""}) currentCountryCode?: string;
    private selectedCountry?: Country | null;
    private countryInput: string = "";
    private showProposals: boolean = false;
    private countryProposal: Array<Country> = [];
    private isLoading: boolean = true;

    mounted(){
        this.setCurrentCountry();
    }

    @Watch("countryInput")
    async countryChanged(val: string) {
        if(!val || (this.countryProposal && this.countryProposal.find((prop: Country) => prop.name === val))) {
            return this.countryProposal = [];
        }
        this.countryProposal = this.allCountries.filter((item: Country) => {
            const queryInLowerCase = this.countryInput.toLocaleLowerCase();
            return item.name.toLocaleLowerCase().includes(queryInLowerCase);
        });
    }

    private async proposalSelect(country: Country) {
        if(!country || !country.name) return;
        this.selectedCountry = country;
        this.countryInput = country.name;
        this.$emit("change",this.selectedCountry);
    }

    private inputSelect() {
        this.showProposals = false;
        this.selectedCountry = this.allCountries.find((country: Country) => {
            return country.name === this.countryInput;
        });
        if(!this.selectedCountry && this.countryInput !== ""){
            this.$emit("incorrectInput", this.countryInput);
            return;
        }
        this.$emit("change",this.selectedCountry);
    }

    private clearInputfield() {
        this.selectedCountry = null;
        this.countryInput = "";
        this.$emit("change",this.selectedCountry);
    }


    countryName(): string {
        return this.selectedCountry ? this.selectedCountry.name : "";
    }

    get allCountryCodes(): Array<string> {
        const countryCodes: Array<string>= [];
        const regions = this.$store.state.regions;
        regions.forEach((region: Region) => {
            region.countries.forEach((code: string) => {
                countryCodes.push(code);
            });
        });
        return countryCodes;
    }

    get allCountries(): Array<Country> {
        const countries = this.allCountryCodes.map((code: string) => ({
            code: code,
            name: countryHelper.getCountryName(code)
        }));
        return countries.sort((countryA: Country, countryB: Country) => {
            if (countryA.name < countryB.name) return -1;
            if (countryA.name > countryB.name) return 1;
            return 0;
        });
    }

    setCurrentCountry() {
        this.isLoading = true;
        if (this.currentCountryCode) {
            const currentCountry = this.allCountries.find((country: Country) => {
                return country.code === this.currentCountryCode;
            });
            if (currentCountry) {
                this.selectedCountry = currentCountry;
                this.$emit("change",this.selectedCountry);
                this.countryInput = this.selectedCountry.name;
            }
            else this.countryInput = "";
        }
        this.isLoading = false;
    }

    countryValidator(countryName: string): boolean{
        return this.allCountries.find((country: Country) => {
            return country.name === countryName;
        }) !== undefined;
    }
}
