var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('StoreLayout',{attrs:{"share-button":true}},[_c('div',{staticClass:"content-card"},[_c('div',{staticClass:"headline mb-6"},[_c('h1',[_vm._v(_vm._s(_vm.$t("customerProfile.title")))])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.showInfo)?_c('div',{key:"content",staticClass:"profile-info mb-4"},[_c('div',{staticClass:"info-cards"},[_c('div',{staticClass:"balance-info"},[_c('img',{attrs:{"src":require("../../assets/icons/mobile-data2-grey24.svg")}}),_c('span',[_vm._v(_vm._s(_vm.profileDetails.totalAvailableBalance.sizeValue)+" "+_vm._s(_vm.profileDetails.totalAvailableBalance.sizeUnit))]),_c('label',[_vm._v(_vm._s(_vm.$t("customerProfile.remainingBalance")))])]),(_vm.availableEsim)?_c('div',{staticClass:"esim-info ml-8",on:{"click":function($event){return _vm.openUrl(_vm.availableEsim.installationUrl)}}},[_c('img',{attrs:{"src":require("../../assets/icons/esim-white.svg")}}),_c('label',[_vm._v(_vm._s(_vm.$t("customerProfile.installEsim")))])]):_vm._e()]),(_vm.profileDetails.balances.length > 0)?_c('h2',{staticClass:"mt-10"},[_vm._v(_vm._s(_vm.$t("customerProfile.remainingBalance")))]):_vm._e(),(_vm.profileDetails.balances.length > 0)?_c('TableList',{staticClass:"mt-6 table-list",attrs:{"columns":_vm.isMobile ? _vm.tableColumnsRemainingBalanceMobile : _vm.tableColumnsRemainingBalance,"rows":_vm.profileDetails.balances},scopedSlots:_vm._u([{key:"column_expiration",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(data.balance.expiresAt))+" ")]}},{key:"column_remaining",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.balance.availableBalance.sizeValue)+" "+_vm._s(data.balance.availableBalance.sizeUnit)+" ")]}},{key:"column_package",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.balance.name)+" ")]}},{key:"column_purchased",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(data.balance.activatedAt))+" ")]}},{key:"column_price",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm._f("fiatFormat")(data.price.priceValue))+" ")]}}],null,false,1754930555)}):_vm._e(),_c('h2',{staticClass:"mt-10"},[_vm._v(_vm._s(_vm.$t("customerProfile.devices")))]),_c('TableList',{staticClass:"mt-6 table-list",attrs:{"columns":_vm.isMobile ? _vm.tableColumnsDevicesMobile : _vm.tableColumnsDevices,"rows":_vm.profileDetails.relatedEsims},scopedSlots:_vm._u([{key:"column_date",fn:function(ref){
var data = ref.data;
return [_c('span',{class:{'inactive': !data.active}},[_vm._v(_vm._s(_vm._f("dateFormat")(data.activatedAt))+_vm._s(!data.activatedAt ? "---" : ""))])]}},{key:"column_status",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"status-display",class:{'inactive': !data.active,
                                   'status-installed': data.state.toLowerCase() === 'installed',
                                   'status-not-installed': data.state.toLowerCase() !== 'installed' && data.state.toLowerCase() !== '---'}},[_vm._v(" "+_vm._s(data.state.toLowerCase())+" ")])]}},{key:"column_link",fn:function(ref){
                                   var data = ref.data;
return [(data.active && data.state.toLowerCase() !== 'installed')?_c('a',{attrs:{"href":data.installationUrl,"target":"_self"}},[_vm._v(_vm._s(_vm.$t("customerProfile.installEsim")))]):_c('span',{class:{'inactive': !data.active}},[_vm._v("---")])]}}],null,false,356756093)})],1):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.showInfo)?_c('div',{key:"spinner",staticClass:"loading-spinner"},[_c('TDSSpinner',{attrs:{"size":40,"border-width":4,"color":"#3D3D3D"}})],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }