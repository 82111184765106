





































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {
        TDSSpinner
    }
})
export default class TDSButton extends Vue {
    @Prop({type: Array, required: true}) items!: any;
    @Prop({type: String, default: "..."}) defaultMessage!: string;
    @Prop({type: Boolean, default: false}) isFilter!: boolean;

    //  The following two props indentify how the option element
    //  in the select looks like. Basically an option element looks like:
    //  <option value="{{ value }}">{{ text }}</option>
    @Prop({type: String, required: true}) valueKey!: string;
    @Prop({type: String, required: true}) textKey!: string;
    @Prop({type: Object, default: null}) initialSelect!: any;
    @Prop({type: Boolean, default: true}) allowClear!: boolean;
    @Prop({type: Boolean, default: false}) capitalize!: boolean;
    @Prop({type: String, default: ""}) queryName!: string;
    @Prop({type: Boolean, default: false}) inactive!: boolean;
    @Prop({type:Boolean, default:true}) searchable!: boolean;
    @Prop({type: Boolean, default: false}) clearOnSelect!: boolean;
    @Prop({type: String, default: "calendar-grey24"}) icon!: string;

    private selected: any = null;
    private isActive: boolean = false;
    private query: string = "";
    private bodyElement: any = null;
    private componentId: string = "select" + Math.random().toString(36).substr(2, 6);
    private initial: boolean = false;
    private activeItemIndex: number = 0;

    get list() {
        return this.items.filter((item: any) => {
            if (!this.query) {
                return true;
            }
            const queryInLowerCase = String(this.query).toLocaleLowerCase();
            if (String(item[this.valueKey]).toLocaleLowerCase().includes(queryInLowerCase)) {
                return true;
            }
            if (String(item[this.textKey]).toLocaleLowerCase().includes(queryInLowerCase)) {
                return true;
            }
            return false;
        });
    }

    @Watch("isActive")
    isActiveChanged(value: boolean) {
        if (value) {
            this.$nextTick(() => {
                (this.$refs.searchInput as HTMLInputElement).focus();
            });
        }
    }

    @Watch("initialSelect")
    initialSelectChanged() {
        if (this.items.length > 0 && this.initial && this.initialSelect) {
            const key = Object.keys(this.items[0])[0];
            this.selected = this.items.find((i: any) => i[key] === this.initialSelect[key]);
            this.initial = false;
        }
    }

    mounted () {
        this.bodyElement = document.querySelector("body");
        this.$nextTick(() => {
            if (this.items.length > 0 && this.initial && this.initialSelect) {
                const key = Object.keys(this.items[0])[0];
                this.selected = this.items.find((i: any) => i[key] === this.initialSelect[key]);
                this.initial = false;
            }
            if (this.queryName && String(history.state?.path).includes(this.queryName)) {
                const pathEntry = String(history.state?.path).substr(String(history.state?.path).indexOf("?") + 1).split("&").find((item: any) => item.includes(this.queryName));
                if (pathEntry) this.selected = this.items.find((item: any) => String(item[this.valueKey]) === decodeURIComponent(pathEntry.split("=")[1]));
            }
            if (this.selected) this.$emit("change", this.selected);
        });
    }

    private closeSelect () {
        this.isActive = false;
        this.initial = false;
        this.activeItemIndex = -1;
    }
    private toggleSelect () {
        if (!this.inactive) {
            this.isActive = !this.isActive;
            if (this.isActive) {
                this.closeSelectOnOuterClick();
            }
        }
    }
    private select (item: any) {
        this.selected = item;
        this.isActive = false;
        this.$emit("change", this.selected);
        this.initial = false;
        this.activeItemIndex = -1;
        if (this.queryName) this.setQueryParam(this.selected);
        if (this.clearOnSelect) {
            this.$nextTick(this.emitlessClear);
        }
    }
    private clear () {
        this.selected = null;
        this.closeSelect();
        this.$emit("change", null);
        this.initial = false;
        if (this.queryName) this.removeQueryParam();
        this.activeItemIndex = -1;
    }
    private emitlessClear () {
        this.selected = null;
        this.initial = false;
        this.activeItemIndex = -1;
        if (this.queryName) this.removeQueryParam();
    }
    private closeSelectOnOuterClick () {
        this.$nextTick(() => {
            const outsideClickListener = (event: Event) => {
                if (!(event.target as HTMLElement).closest("#" + this.componentId)) {
                    this.closeSelect();
                    removeClickListener();
                }
            };
            const removeClickListener = () => {
                this.bodyElement.removeEventListener("click", outsideClickListener);
            };
            this.bodyElement.addEventListener("click", outsideClickListener);
            this.initial = false;
        });
    }
    private setQueryParam (selected: any) {
        // this.$pushStateURL({ [this.queryName]: selected[this.valueKey] });
    }
    private removeQueryParam () {
        // this.$pushStateURL({ [this.queryName]: undefined });
    }
    private onKeyDown () {
        if (this.activeItemIndex < this.items.length - 1) {
            this.activeItemIndex = this.activeItemIndex + 1;
            this.itemScrolling();
        }
    }
    private onKeyUp () {
        if (this.activeItemIndex > 0) {
            this.activeItemIndex = this.activeItemIndex - 1;
            this.itemScrolling();
        }
    }
    private onKeyEnter () {
        this.select(this.items[this.activeItemIndex]);
        this.activeItemIndex = -1;
    }
    private itemScrolling () {
        const height: number = ((this.$refs.liElement as Element[])[this.activeItemIndex] as HTMLLIElement).clientHeight;

        (this.$refs.ulElement as HTMLUListElement).scrollTop = height * this.activeItemIndex;
    }

}
