

























import { Component, Vue } from "vue-property-decorator";
import DefaultFooter from "@/components/partials/DefaultFooter.vue";
import Triangle from "@/components/partials/Triangle.vue";
import DefaultNavigationBar from "@/components/partials/DefaultNavigationBar.vue";

@Component({
    components: {
        DefaultNavigationBar,
        DefaultFooter,
        Triangle
    }
})
export default class DefaultLandingPageLayout extends Vue {}
