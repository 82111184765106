











import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import TDSSelect from "@/components/common/TDSSelect.vue";
import {Filter} from "@/interfaces/entities/Filter";

@Component({
    components: {TDSSelect}
})
export default class FilterPanel extends Vue {
    @Prop({type: Array, required: true}) items!: any;
    @Prop({type: Array, required: true}) filteredItems!: any;
    @Prop({type: Object, required: true}) filters!: { [key: string]: Filter };
    private filterEntries: { [key: string]: Filter[] } = {};
    private activeFilters: Filter[] = [];

    created() {
        this.getFilteredItems();
        this.getAllFilterEntries();
    }

    @Watch("items")
    itemsChanged() {
        this.getFilteredItems();
    }

    @Watch("activeFilters")
    activeFiltersChanged(val: any) {
        this.getFilteredItems();
    }

    private getFilteredItems() {
        if(this.activeFilters.length === 0) this.$emit("update:filteredItems", this.items);
        this.$emit("update:filteredItems", this.items.filter((item: any) => {
                return this.activeFilters.every((filter: any) => {
                // debugger;
                const prop: string = this.filters[filter.key].key;
                return this.getItemProp(item, prop) === filter.val;
            });
        }));
    }

    private getAllFilterEntries() {
        Object.keys(this.filters).forEach((filter: string) => this.filterEntries[filter] = this.getFilterEntries(String(this.filters[filter].val), this.filters[filter].label!));
    }

    private getFilterEntries(prop: string, label: (item: any) => string) {
        const unique: Filter[] = [];
        this.items.forEach((item: any) => {
            if (!unique.find((u: any) => u.val === this.getItemProp(item, prop))) {
                unique.push({
                    val: this.getItemProp(item, prop),
                    key: label(item)
                });
            }
        });
        return unique;
    }

    private getItemProp(item: any, prop: string): string | number | undefined {
        if(!item) return undefined;
        const props = prop.split(".");
        if(props.length === 1) return item[prop];
        else return this.getItemProp(item[props[0]], props.slice(1).join("."));
    }

    private updateFilters(key: string, value: string) {
        const entry = this.activeFilters.find((filter: Filter) => filter.key === key);
        if (entry) this.activeFilters.splice(this.activeFilters.indexOf(entry), 1);
        if (value) this.activeFilters.push({key, val: value});
    }
}
