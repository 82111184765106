






















import {Component, Prop, Vue} from "vue-property-decorator";
import EnterPhoneNumber from "./EnterPhoneNumber.vue";
import {Price} from "@/interfaces/entities/Price";
import PasswordLogin from "./PasswordLogin.vue";
import SmsCodeLogin from "./SmsCodeLogin.vue";
import Checkout from "./Checkout.vue";
import axiosInstance from "@/util/axios";
import {AxiosInstance} from "axios";

enum ViewState {
    ENTER_PHONE_NUMBER, AUTHENTICATE_SMS, AUTHENTICATE_PASSWORD_2FA, CHECKOUT
}

interface Dictionary {
    [key: string]: string;
}

interface AuthStartResponse {
    data: {
        authType: string;
    };
}

interface AuthSubmitResponse {
    data: {
        token: string;
    };
}

@Component({
    components: {PasswordLogin, Checkout, SmsCodeLogin, EnterPhoneNumber}
})
export default class CustomPaymentOption extends Vue {
    private state: ViewState = ViewState.ENTER_PHONE_NUMBER;
    private ViewState: (typeof ViewState) = ViewState;
    private inProgress: boolean = false;
    private username: string = "";
    private token: string = "";

    @Prop({type: Array, default: () => []})
    private totalPrices!: Array<Price>;

    async next(data: string | Dictionary) {
        this.inProgress = true;
        try {
            switch (this.state) {

                case ViewState.ENTER_PHONE_NUMBER:
                    if (await this.startAuth(data as string) === "smsCode") {
                        this.state = ViewState.AUTHENTICATE_SMS;
                    } else {
                        this.state = ViewState.AUTHENTICATE_PASSWORD_2FA;
                    }
                    break;

                case ViewState.AUTHENTICATE_SMS:
                    this.token = await this.submitSmsCodeAuth(data as string);
                    this.state = ViewState.CHECKOUT;
                    break;

                case ViewState.AUTHENTICATE_PASSWORD_2FA:
                    this.token = await this.submitPasswordAuth(data as Dictionary);
                    this.state = ViewState.CHECKOUT;
                    break;

                case ViewState.CHECKOUT:
                    this.$emit("submit", this.token);
                    break;
            }
        } catch (e) {
            console.error("[DENTPayment] Error during checkout: ", e);
        }
        this.inProgress = false;
    }

    async startAuth(phoneNumber: string): Promise<string> {
        const response: AuthStartResponse = await this.request("post", "/crypto-payments/authenticate/start", {
            username: phoneNumber
        });
        this.username = phoneNumber;
        return response.data.authType;
    }

    async submitSmsCodeAuth(code: string): Promise<string> {
        const response: AuthSubmitResponse = await this.request("post", "/crypto-payments/authenticate/submit", {
            username: this.username,
            code: code,
            authType: "smsCode"
        });
        return response.data.token;
    }

    async submitPasswordAuth(data: Dictionary) {
        const response: AuthSubmitResponse = await this.request("post", "/crypto-payments/authenticate/submit", {
            username: this.username,
            code: data.code,
            password: data.password,
            authType: "passwordWith2fa"
        });
        return response.data.token;
    }

    //  We are not using the VueX store, 'cause this is custom for DENT
    async request<RequestType, ResponseType>(method: string, url: string, body?: RequestType): Promise<ResponseType> {
        try {
            let response: ResponseType;
            if (body) { // @ts-ignore
                response = await (axiosInstance as AxiosInstance)[method](url, body);
            } else { // @ts-ignore
                response = await (axiosInstance as AxiosInstance)[method](url);
            }
            console.log("[DentPayment] Got response for '" + url + "': ", response);
            return response;
        } catch (e) {
            const errorMessage = e.response?.data?.message ?? this.$t("general.unexpectedError").toString();
            this.$toastr.error(errorMessage);
            throw e;
        }
    }
}
