

























































































































































import { Component, Vue } from "vue-property-decorator";
import TableList from "@/components/partials/TableList.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import CSVExport from "@/components/partials/CSVExport.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import SoldItemCardContent from "@/components/partials/YourStore/SoldItemCardContent.vue";
import TDSDatePicker, { TimeRange } from "@/components/common/TDSDatePicker.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { SoldItem } from "@/interfaces/entities/SoldItem";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSDate, { TDSDateUnit } from "@/util/TDSDate";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import { Product } from "@/interfaces/entities/Product";

interface Filters {
    name: string;
    label: string;
    active: boolean;
}
interface Filter {
    val: string;
    key: string;
    label?: (item: any) => string;
    placeholder?: string;
}

@Component({
    components: {
        FilterPanel,
        TDSSpinner,
        CSVExport,
        TableList,
        TDSButton,
        TDSModal,
        SoldItemCardContent,
        TDSDatePicker,
        Inputfield
    }
})
export default class History extends Vue {
    private tableColumnsHistory: object = {
        date: {
            label: this.$t("tableHeader.dateOfSale") as string,
            width: "140px",
            sortable: true
        },
        name: {
            label: this.$t("tableHeader.name") as string,
            sortable: true,
            sortBy: (item: SoldItem) => item.balance.name
        },
        // added later when sountrySet is in response
        // coverage: {
        //     label: this.$t("tableHeader.coverage") as string,
        //     sortable: true, width: "90px",
        //     align: "right",
        //     sortBy: (product: Product) => product.countrySet
        // },
        volumeSize: {
            label: this.$t("tableHeader.volume") as string,
            sortable: true,
            width: "80px",
            align: "right",
            sortBy: (item: SoldItem) => item.balance.size.sizeValue
        },
        validitySize: {
            label: this.$t("tableHeader.validity") as string,
            sortable: true,
            width: "100px",
            align: "center",
            sortBy: (item: SoldItem) => item.balance.validitySize
        },
        salesChannel: {
            label: this.$t("tableHeader.salesChannel") as string,
            sortable: true,
            sortBy: (item: SoldItem) => item.salesChannelTypeCode,
            width: "70px"
        },
        purchasePrice: {
            label: this.$t("tableHeader.purchasePrice") as string,
            sortable: true,
            sortBy: (item: SoldItem) => item.purchasePrice.priceValue,
            width: "120px",
            align: "right"
        },
        retailPrice: {
            label: this.$t("tableHeader.retailPrice") as string,
            sortable: true,
            sortBy: (item: SoldItem) => item.retailPrice.priceValue,
            width: "120px",
            align: "right"
        },
        details: { label: "", width: "64px" }
    };
    private showDownloadModal: boolean = false;
    private isLoading: boolean = false;
    private showTimeRangeModal: boolean = false;
    private shownTimeRange: string = "";
    private showUserFilterModal: boolean = false;
    private shownUserFilter: string = "";
    private filteredItems: SoldItem[] = [];
    private filters: { [key: string]: Filter } = {
        // added later when coverage is available in API response
        // coverage: {
        //     val: "countrySet",
        //     key: "countrySet",
        //     label: (item: any) => this.$options.filters?.countryName(item.countrySet),
        //     placeholder: "All Coverage"
        // },
        validity: {
            val: "balance.validitySize",
            key: "balance.validitySize",
            label: (item: any) => item.balance.validitySize + " " + item.balance.validityUnit,
            placeholder: "All Validities"
        }
    };
    private filterTypes: Array<Filters> = [
        {
            name: "email",
            label: this.$t("general.emailLabel") as string,
            active: true
        },
        {
            name: "iccid",
            label: "ICCID",
            active: false
        }
    ];
    private filterTypeInput: string = "";
    private selectedTimeRange?: TimeRange = undefined;
    private datePickerHasError: boolean = false;
    private timeZoneOffsetInMinutes: number = new Date().getTimezoneOffset();
    private noSales: boolean = true;

    get isEmpty() {
        return !this.$store.state.soldItems || this.$store.state.soldItems.length === 0;
    }

    get soldItems() {
        return this.$store.state.soldItems;
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("yourStore.history.pageTitle");
        this.fetchData();
        this.selectedTimeRange = {
            start: new TDSDate(Date.now()).add(-3, TDSDateUnit.YEAR),
            end: new TDSDate(Date.now())
        };
    }

    private resetTime() {
        console.log("reset Time");
        this.selectedTimeRange = undefined;
        this.toggleTimeRangeModal();
        this.setShownTimeRange();
        this.fetchData();
    }

    private resetUser() {
        console.log("reset User");
        this.filterTypeInput = "";
        this.closeFilterModal();
        this.shownUserFilter = "";
        this.fetchData();
    }

    private toggleTimeRangeModal() {
        this.showTimeRangeModal = !this.showTimeRangeModal;
    }
    private openUserFilterModal() {
        if (!this.shownUserFilter) this.filterTypeInput = "";
        this.showUserFilterModal = true;
    }
    private closeFilterModal() {
        this.showUserFilterModal = false;
    }

    private switchType(type: Filters) {
        type.active = true;
        this.filterTypes.filter((filterType: Filters) => filterType.name !== type.name).forEach((filterType: Filters) => (filterType.active = false));
    }

    getStartDate(): string | undefined {
        return this.selectedTimeRange?.start?.add(this.timeZoneOffsetInMinutes / 60, TDSDateUnit.HOUR)?.format("YYYY-MM-DDTHH:mm:ss.000Z");
    }

    getEndDate(): string | undefined {
        return this.selectedTimeRange?.end
            ? this.selectedTimeRange?.end?.add(this.timeZoneOffsetInMinutes / 60, TDSDateUnit.HOUR)?.format("YYYY-MM-DDTHH:mm:ss.000Z")
            : new TDSDate(Date.now()).add(this.timeZoneOffsetInMinutes / 60, TDSDateUnit.HOUR).format("YYYY-MM-DDTHH:mm:ss.000Z");
    }
    private async fetchData() {
        this.isLoading = true;
        const start = this.getStartDate();
        const end = this.getEndDate();
        const activeFilter = this.filterTypes.find((filterType: Filters) => filterType.active);
        const userFilterType = activeFilter?.name || undefined;
        try {
            await this.$store.dispatch("FETCH_SOLD_ITEMS", {
                start,
                end,
                userFilterType,
                userFilterValue: this.filterTypeInput
            });
        } catch (e) {
            console.error("[History] Failed fetching history items");
        } finally {
            this.isLoading = false;
            if (this.noSales) this.noSales = !this.$store.state.soldItems || this.$store.state.soldItems.length === 0;
        }
    }

    private showDetails(row: SoldItem) {
        if (this.$route.query.sortDir && this.$route.query.sortKey) {
            this.$router.push({
                path: "/your-store/history/" + row.uid,
                query: {
                    sortDirHistory: this.$route.query.sortDir,
                    sortKeyHistory: this.$route.query.sortKey
                }
            });
        } else {
            this.$router.push("/your-store/history/" + row.uid);
        }
    }

    private filterByUser() {
        const activeFilter = this.filterTypes.find((filterType: Filters) => filterType.active);
        if (!this.filterTypeInput || !activeFilter) return;
        this.fetchData();
        this.shownUserFilter = activeFilter.label;
        this.showUserFilterModal = false;
    }

    private timeRangeSelectedInModal(eventData: TimeRange) {
        this.selectedTimeRange = eventData;
        console.log("[History] User selected time range: ", this.selectedTimeRange);
    }

    private filterByTimeRange() {
        if (this.datePickerHasError) return;
        this.fetchData();
        this.setShownTimeRange();
        this.showTimeRangeModal = false;
    }

    setShownTimeRange() {
        this.shownTimeRange = "";
        if (!this.selectedTimeRange) this.shownTimeRange = "Select Timerange";
        if (this.selectedTimeRange?.start) this.shownTimeRange += this.selectedTimeRange.start.format("MMM DD, YYYY");
        if (this.selectedTimeRange?.start && this.selectedTimeRange?.end) this.shownTimeRange += " - ";
        if (this.selectedTimeRange?.end) this.shownTimeRange += this.selectedTimeRange.end.format("MMM DD, YYYY");
    }
}
