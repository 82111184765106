


















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import DefaultFooter from "@/components/partials/DefaultFooter.vue";
import MobileMenuButton
    from "@/components/partials/Navigation/MobileMenuButton.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {TDSSpinner, MobileMenuButton, DefaultFooter}
})
export default class DefaultNavigationBar extends Vue {
    @Prop({type: Boolean, default: false}) shareButton!: boolean;
    @Prop({type: String, default: null}) profileLink?: string;

    private mobileNavOpen: boolean = false;
    private isLoading: boolean = false;
    private isPhone: boolean = false;

    get authenticated(): boolean {
        return !!this.$store.state.auth;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get previousView(): boolean {
        return this.$store.state.previousView;
    }

    created() {
        this.checkPhone();
    }

    @Watch("mobileNavOpen")
    mobileNavChanged(newVal: boolean) {
        if (newVal) document.body.style.position = "fixed";
        else document.body.style.position = "relative";
    }

    @Watch("isMobile")
    mobileChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
        this.checkPhone();
    }

    @Watch("authenticated")
    authChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
    }

    public async login(): Promise<void> {
        this.isLoading = true;
        try {
            await this.$authenticator.signIn();
        } catch(e) {
            this.$toastr.error(this.$t("navigation.errors.login"), e);
            this.isLoading = false;
        }
    }

    public async logout(): Promise<void> {
        this.isLoading = true;
        try {
            await this.$authenticator.signOut();
        } catch(e) {
            this.$toastr.error(this.$t("navigation.errors.logout"), e);
            this.isLoading = false;
        }
    }

    private checkPhone() {
        this.isPhone = Boolean(navigator.appVersion.includes("Android") || navigator.appVersion.includes("iPhone") || navigator.appVersion.includes("Mac") );
    }

    private async share () {
        if(this.isPhone && this.isMobile) {
            await navigator.share({ url: window.location.href });
            console.log("Data was shared successfully");
        } else {
            const dummy = document.createElement("input");

            document.body.appendChild(dummy);
            dummy.value = window.location.href;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            this.$toastr.success(this.$t("components.inputfield.urlCopied"), true, true);
        }
    }
}
