











































import { Component, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TableList from "@/components/partials/TableList.vue";
import Dashboard from "@/components/partials/YourStore/Dashboard.vue";
import Inventory from "@/components/partials/YourStore/Inventory.vue";
import History from "@/components/partials/YourStore/History.vue";
import SalesChannels from "@/components/partials/YourStore/SalesChannels.vue";
import BalanceHeader from "@/components/partials/BalanceHeader.vue";
import { JoinerBonusInfo } from "@/interfaces/entities/JoinerBonusInfo";

interface NavOption {
    value: string;
    page: string;
    icon: string;
}

interface HeaderInfoItem {
    title: string;
    subtitle: string;
    linkText: string;
    internalLink?: string;
    externalLink?: string;
    hideVar: string;
}

@Component({
    components: {
        SalesChannels,
        TableList,
        DefaultLayout,
        BalanceHeader,
        TDSButton,
        Dashboard,
        Inventory,
        History
    }
})
export default class YourStore extends Vue {
    private navOptions: NavOption[] = [
        // ADDED IN NEXT WP
        { value: this.$t("yourStore.dashboard.title") as string, page: "dashboard", icon: "dashboard2" },
        { value: this.$t("yourStore.inventory.title") as string, page: "inventory", icon: "graph-bar" },
        { value: this.$t("yourStore.channels.title") as string, page: "channels", icon: "market-place" },
        { value: this.$t("yourStore.history.title") as string, page: "history", icon: "investment-history" }
    ];
    private selectedPage: string = "";
    private headerInfos: Array<HeaderInfoItem> = [];

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        if (!this.$route.params.page) this.$router.push("/your-store/dashboard");
        this.pageChanged(this.$route.params);
        if (!this.$route.params.id && !this.isMobile) this.init();
    }

    @Watch("$route.params")
    paramsChanged(newParams: any) {
        if (!this.$route.params.page) this.$router.push("/your-store/dashboard");
        this.pageChanged(newParams);
        if (!this.$route.params.id && !this.isMobile) this.init();
    }

    private init() {
        if (this.$store.state.soldItems.length === 0) {
            this.$store.dispatch("FETCH_SOLD_ITEMS").then(() => {
                if (this.$store.state.soldItems.length === 0) {
                    const headerInfos: Array<HeaderInfoItem> = [];
                    if (!this.$storage.get("hideChannelHint")) {
                        headerInfos.push({
                            title: String(this.$t("yourStore.salesChannels.title")),
                            subtitle: String(this.$t("yourStore.salesChannels.subtitle")),
                            linkText: String(this.$t("yourStore.salesChannels.linkText")),
                            internalLink: "/your-store/channels",
                            hideVar: "hideChannelHint"
                        });
                    }
                    this.$store.dispatch("FETCH_JOINER_BONUS_INFO").then((joinerBonusInfo: JoinerBonusInfo) => {
                        if (joinerBonusInfo.showToUser && !this.$storage.get("hideJoinerBonus")) {
                            headerInfos.push({
                                title: String(this.$t("yourStore.welcomeBonus.title")),
                                subtitle: String(this.$t("yourStore.welcomeBonus.subtitle", [joinerBonusInfo.amount + "x " + joinerBonusInfo.name])),
                                linkText: String(this.$t("yourStore.welcomeBonus.linkText")),
                                internalLink: "/your-store/inventory",
                                hideVar: "hideJoinerBonus"
                            });
                        }
                        this.headerInfos = headerInfos;
                    });
                }
            });
        } else {
            this.headerInfos = [];
        }
    }

    private backgroundImage(option: NavOption) {
        return this.selectedPage === option.page
            ? "url(" + require("../../assets/icons/" + option.icon + "-white.svg") + ")"
            : "url(" + require("../../assets/icons/" + option.icon + "-fullGrey.svg") + ")";
    }

    private pageChanged(newParams: any) {
        if (!newParams.page || !this.navOptions.find((option: NavOption) => option.page === newParams.page)) return;
        this.selectedPage = newParams.page;
    }

    private showSection(page: string) {
        if (page !== this.selectedPage) this.$router.push("/your-store/" + page);
    }

    private closeHeaderInfo(hideVar: string) {
        this.headerInfos.splice(this.headerInfos.length - 1, 1);
        this.$storage.set(hideVar, true);
    }
}
