import Chart, {ChartDataSets, ChartTooltipModel, ChartTooltipModelBody} from "chart.js";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

export interface LineDataType {
    id: string;
    label?: string;
    data: number[];
    backgroundColor?: string;
    borderColor: string;
    borderWidth?: number;
    percentage?: number;
}

@Component
export default class MultiLineChart extends Vue {
    @Prop({type: Array}) items!: LineDataType[];
    @Prop({type: String, default: "Value"}) label!: string;
    @Prop({type: Array}) labels!: string[];
    @Prop({type: String, default: "rgba(251,86,81,0.0)"}) areaColor!: string;
    @Prop({type: String, default: "#FE6C13"}) lineColor!: string;
    @Prop({type: Number, default: 2}) lineWidth!: number;
    @Prop({type: Number, default: 133}) heightInPixel!: number;
    @Prop({type: Number, default: 5}) amountOfXAxisLabels!: number;
    @Prop({type: Boolean, default: true}) showLabels!: boolean;
    @Prop({type: Number, default: undefined}) maxValue!: number;
    @Prop({type: Boolean, default: false}) isBarChart!: boolean;

    private canvasId: string = "chart-" + Math.random().toString(36).substr(2, 5);
    private canvasContext: any = null;
    private canvasNode: any = null;
    private chart: any = null;

    mounted() {
        this.canvasNode = document.getElementById(this.canvasId);
        this.canvasContext = this.canvasNode.getContext("2d");
        this.draw();
    }
    beforeDestroy() {
        if(this.chart) {
            this.chart.destroy();
        }
        this.chart = null;
    }
    getLabels(): number[] {
        let maxLength = 0;
        this.items.forEach((item: LineDataType) => {
            if(item.data.length > maxLength) maxLength = item.data.length;
        });
        return Array(maxLength).fill(null).map((_: any, i: number) => i);
    }
    // get noData(): boolean {
    //     if(this.items.length <= 0) return true;
    //     const sumUp = this.items.reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
    //     return sumUp <= 0;
    // }

    @Watch("items")
    itemsChanged() {
        this.draw();
    }

    @Watch("isBarChart")
    typeChanged() {
        this.chart.destroy();
        this.drawNewChart();
    }

    private draw() {
        if (this.chart) {
            this.updateChart();
        } else {
            this.drawNewChart();
        }
    }

    private getDatasets() { // trivial
        const dataSets: ChartDataSets[] = [];
        this.items.forEach((item: LineDataType) => {
            dataSets.push({
                label: item.label + ": " + item.borderColor,
                data: item.data,
                backgroundColor: this.isBarChart? item.borderColor : this.areaColor,
                borderColor: item.borderColor,
                borderWidth: item.borderWidth || this.lineWidth,
                pointBorderColor: "rgba(0,0,0,0)",
                pointBackgroundColor: "rgba(0,0,0,0)",
                lineTension: 0,
                borderJoinStyle: "round"
            });
        });
        return dataSets;
    }

    private updateChart() {
        this.chart.data.labels = this.labels || this.getLabels();
        this.chart.data.datasets = this.getDatasets();
        this.chart.options.scales.yAxes[0].ticks.max = this.maxValue;
        this.chart.update();
    }

    private drawNewChart() {
        this.chart = new Chart(this.canvasContext, {
            type: this.isBarChart? "bar" : "line",
            data: {
                labels: this.labels || this.getLabels(),
                datasets: this.getDatasets()
            },
            options: {
                tooltips: {
                    enabled: false,

                    custom: (tooltipModel: ChartTooltipModel) => {

                        if (!this.showLabels) return;

                        // Tooltip Element
                        let tooltipEl = document.getElementById("chartjs-tooltip");

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement("div");
                            tooltipEl.id = "chartjs-tooltip";
                            tooltipEl.innerHTML = "<div class=\"tooltip infobox\" x-placement=\"bottom\"></div>";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.display = "none";
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove("above", "below", "no-transform");
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add("no-transform");
                        }

                        function getBody(bodyItem: ChartTooltipModelBody) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = this.labels.find((l: string) => l === tooltipModel.title[0]);
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = "<div class=\"tooltip-arrow\"></div><div class=\"tooltip-inner\">";
                            if (titleLines) innerHtml += "<label>" + titleLines + "</label>";
                            bodyLines.forEach(function (body: string[], i: number) {
                                innerHtml += "<label class='package-label'>" + "<div style='background-color:" + body[0].split(": ")[1] + ";'></div> <span style='font-size: 16px'>" + parseFloat(body[0].split(": ")[2]).toFixed( 2 )  + "</span></label>";
                            });
                            innerHtml += "<label style='margin-top: 5px;'>" + this.$t("yourStore.dashboard.charts.perPackage.label") +"</label></div>";

                            const tableRoot: any = tooltipEl.querySelector(".tooltip");
                            tableRoot.style.opacity = 1;
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        // @ts-ignore
                        const position = this.$refs.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.display = "block";
                        // @ts-ignore
                        tooltipEl.style.zIndex = 3;
                        tooltipEl.style.position = "absolute";
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth/2) + "px";
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - (tooltipModel.height*1.6) + "px";
                        tooltipEl.style.pointerEvents = "none";
                    }
                },
                animation: {
                    duration: 0,
                    easing: "easeOutExpo"
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            zeroLineColor: "#e3e3e3",
                            drawTicks: false
                        },
                        ticks: {
                            display: true,
                            fontSize: 10,
                            fontFamily: "FFMark W04",
                            fontStyle: "bold",
                            fontColor: "#e3e3e3",
                            padding: 8,
                            min: 0,
                            max: this.maxValue
                        }
                    }],
                    xAxes: [{
                        display: false,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            maxTicksLimit: this.amountOfXAxisLabels,
                            // labelOffset: 10,
                            fontSize: 10,
                            fontFamily: "FFMark W04",
                            maxRotation: 0,
                            minRotation: 0,
                            mirror: false,
                            lineHeight: 0,
                            padding: -5,
                            min: 0
                        }
                    }]
                }
            }
        });
    }
}
