








import {Component, Prop, Vue} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";

@Component ({
  components: {TDSButton}
})
export default class BalanceHeader extends Vue {
    @Prop({type: String, default: "white"}) color!: string;
    get dentBalance() {
        return this.$store.state.user?.balance || 0;
    }
}
