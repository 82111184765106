import axios from "@/util/axios";
import {VuexActionContext} from "@/store/index";
import {GetESIMDataResponse} from "@/interfaces/httpResponses/GetESIMDataResponse";
import {GetCustomerDataResponse} from "../interfaces/httpResponses/GetCustomerDataResponse";
import {CustomerProfile} from "../interfaces/entities/CustomerProfile";
import {ActivatedItem} from "@/interfaces/entities/ActivatedItem";
import {AxiosResponse, ResponseType} from "axios";
import {UpdateCustomerRequest} from "@/interfaces/httpRequests/UpdateCustomerRequest";
import {UpdateCustomerResponse} from "@/interfaces/httpResponses/UpdateCustomerResponse";

export interface CustomerState {
    validCustomerTypes: Array<string>;
}

export const customerState: CustomerState = {
    validCustomerTypes: ["new", "existing", "guest"]
};

export const customerMutations = {};

export const customerActions = {
    async FETCH_CUSTOMER_DATA(context: VuexActionContext<CustomerProfile>, token: string): Promise<GetCustomerDataResponse> {
        const response = await axios.get("/activations/customers/profile?token=" + token);
        console.log("[CustomerStore] Got customer: ", response.data);
        return response.data;
    },

    async GET_CUSTOMER_PROPOSALS(context: VuexActionContext<Array<ActivatedItem>>, query: string): Promise<Array<ActivatedItem>> {
        if(!query) return [];
        const response = await axios.post("activations/search-customers", {
            searchKey: "EMAIL",
            searchQuery: query,
            searchMode: "startsWith"
        });
        console.log("[CustomerStore] Got customers: ", response.data.customers);
        return response.data.customers;
    },

    async GET_CUSTOMER(context: VuexActionContext<ActivatedItem | null>, payload: {searchKey: string; searchQuery: string | number; searchMode: string}): Promise<ActivatedItem | null> {
        if(!payload) return null;
        const response = await axios.post("activations/search-customers", payload);
        console.log("[CustomerStore] Got customer: ", response.data);
        return response.data.customers[0] || null;
    },

    async UPDATE_CUSTOMER(context: VuexActionContext<UpdateCustomerRequest>, payload: UpdateCustomerRequest): Promise<UpdateCustomerResponse> {
        const requestBody = {
            customerEmail: payload.customerEmail
        };
        const response = await axios.put("activations/customers/" + payload.customerUid, requestBody);
        console.log("[CustomerStore] Set customer email");
        return response;
    }
};
