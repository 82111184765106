import { render, staticRenderFns } from "./VidText.vue?vue&type=template&id=5cfb2c57&scoped=true&"
import script from "./VidText.vue?vue&type=script&lang=ts&"
export * from "./VidText.vue?vue&type=script&lang=ts&"
import style0 from "./VidText.vue?vue&type=style&index=0&id=5cfb2c57&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cfb2c57",
  null
  
)

export default component.exports