





























































































import { Vue, Component, Watch } from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSInputField from "@/components/common/TDSInputField.vue";
import TableList from "@/components/partials/TableList.vue";
import { ActivatedItem } from "@/interfaces/entities/ActivatedItem";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import AddCustomerEmailModal from "@/components/partials/AddCustomerEmailModal.vue";

interface Customer {
    email: string; // null;
    uid: string; // "0e3ebcc7-b117-4529-b979-244e273705d5";
    profileUrl: string; // null;
}

@Component({
    components: { AddCustomerEmailModal, TDSModal, TableList, TDSInputField, TDSButton }
})
export default class NewCustomer extends Vue {
    private email: string = "";
    private iccid: string = "";
    private emailProposals: Array<Customer> = [];
    private iccidValid: boolean = true;
    private searchMethod: string = "email";
    private customerInfos: ActivatedItem | null = null;
    private showModal: boolean = false;
    private showEmailModal: boolean = false;
    private hasError: string = "";
    private tableDeviceColumns: object = {
        email: { label: this.$t("tableHeader.email") as string },
        balance: { label: this.$t("tableHeader.balance") as string, width: "150px" },
        devices: { label: this.$t("tableHeader.devices") as string, width: "100px" }
    };
    private tableDeviceColumnsMobile: object = {
        email: { label: this.$t("tableHeader.email") as string },
        balance: { label: this.$t("tableHeader.balance") as string, width: "100px" },
        link: { label: "", width: "48px", align: "right" }
    };

    private tableDeviceColumnsMobile1: object = {
        devices: { label: this.$t("tableHeader.devices") as string },
        balance: { label: this.$t("tableHeader.balance") as string }
    };

    private tableDeviceColumnsMobile2: object = {
        email: { label: this.$t("tableHeader.email") as string }
    };

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    @Watch("email")
    async emailChanged(val: string) {
        if (!val || (this.emailProposals && this.emailProposals.find((prop: Customer) => prop.email === val))) {
            return (this.emailProposals = []);
        }
        const response = await this.$store.dispatch("GET_CUSTOMER_PROPOSALS", val);
        if (response.length === 0) {
            this.emailProposals = [
                {
                    email: "",
                    uid: "0",
                    profileUrl: ""
                }
            ];
        } else
            this.emailProposals = response.map((entry: any) => {
                return entry.customer;
            });
    }

    openAddEmailModal() {
        this.showEmailModal = true;
    }

    private switchSearchMethod() {
        if (this.searchMethod === "email") this.searchMethod = "iccid";
        else this.searchMethod = "email";
        this.email = "";
        this.iccid = "";
        this.iccidValid = true;
        this.customerInfos = null;
    }

    private async proposalSelect(customer: Customer) {
        if (!customer || !customer.email) return;
        this.email = customer.email;
        try {
            this.customerInfos = await this.$store.dispatch("GET_CUSTOMER", {
                searchKey: "UID",
                searchQuery: customer.uid,
                searchMode: "isExact"
            });
        } catch (e) {
            console.error("[EXISTING CUSTOMER] Failed fetching customer:", e);
        }
    }

    private async inputSelect() {
        if (this.emailProposals.length > 0 || !this.email) return;
        try {
            this.customerInfos = await this.$store.dispatch("GET_CUSTOMER", {
                searchKey: "EMAIL",
                searchQuery: this.email,
                searchMode: "isExact"
            });
        } catch (e) {
            console.error("[EXISTING CUSTOMER] Failed fetching customer:", e);
        }
    }

    private reload() {
        this.showEmailModal = false;
        this.findByIccid();
    }
    private async findByIccid() {
        if (!this.iccid) return;
        try {
            this.hasError = "";
            this.customerInfos = await this.$store.dispatch("GET_CUSTOMER", {
                searchKey: "ICCID",
                searchQuery: this.iccid,
                searchMode: "isExact"
            });
            if (!this.customerInfos) {
                this.iccidValid = false;
            }
        } catch (e) {
            console.error("[EXISTING CUSTOMER] Failed fetching customer:", e);
            this.iccidValid = false;
        }
    }

    private setCustomer() {
        if (!this.customerInfos) return;
        this.$router.push({ path: "/store/existing/packages", query: { customerUid: this.customerInfos.customer.uid } });
    }

    private clearInputfield() {
        this.email = "";
        this.iccid = "";
        this.iccidValid = true;
        this.customerInfos = null;
    }

    private clearCustomerInfo() {
        this.customerInfos = null;
    }
}
