














































































import {Component, Vue} from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import BalanceHeader from "@/components/partials/BalanceHeader.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import SalesChannel from "@/components/partials/APIDashboard/SalesChannel.vue";
import Webhooks from "@/components/partials/APIDashboard/Webhooks.vue";

@Component({
    components: {Webhooks, SalesChannel, TDSSpinner, TDSModal, TDSButton, Inputfield, DefaultLayout, BalanceHeader}
})
export default class APIDashboard extends Vue {
get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("apiDashboard.pageTitle");
    }

}
