import axios from "@/util/axios";
import {APICredential} from "@/interfaces/entities/APICredential";
import {VuexActionContext} from "@/store/index";
import {GetAPICredentialsResponse} from "@/interfaces/httpResponses/GetAPICredentialsResponse";
import {DeviceVerificationUrlUpdate} from "@/interfaces/entities/DeviceVerificationUrl";
import {WEBHOOK_TYPES, WebhookUrl} from "@/interfaces/entities/WebhookUrl";

export interface APIState {
    apiChannels: APICredential[];
    balanceAlertUrl: string;
    eSimStatusUrl: string;
    customerRegistrationUrl: string;
    countryChangeUrl: string;
}

export const apiState: APIState = {
    apiChannels: [],
    balanceAlertUrl: "",
    eSimStatusUrl: "",
    customerRegistrationUrl: "",
    countryChangeUrl: ""
};
export const APIMutations = {
    SET_CHANNEL_NAME(state: APIState, payload: APICredential) {
        if (!state.apiChannels || state.apiChannels.length <= 0) return;
        const channel = state.apiChannels.find((c: APICredential) => c.clientId === payload.clientId);
        if (channel) channel.name = payload.name;

        // TODO: Attach API to update this info

    },
    ADD_NEW_CHANNEL(state: APIState, apiCredential: APICredential) {
        if (!state.apiChannels) return;
        if(state.apiChannels.find((item: APICredential) => item.clientId === apiCredential.clientId)) return;
        state.apiChannels.push(apiCredential);
    },
    ADD_BALANCEALERT_URL(state: APIState, url: string) {
        state.balanceAlertUrl = url;
    },
    ADD_ESIMSTATUS_URL(state: APIState, url: string) {
        state.eSimStatusUrl = url;
    },
    ADD_CUSTOMERREGISTRATION_URL(state: APIState, url: string) {
        state.customerRegistrationUrl = url;
    },
    ADD_COUNTRYCHANGE_URL(state: APIState, url: string) {
        state.countryChangeUrl = url;
    }
};

export const APIActions = {
    async FETCH_API_CREDENTIALS({commit}: VuexActionContext<APICredential>) {
        await axios.post("/users/current/api-credentials/sync");
        const response: GetAPICredentialsResponse = await axios.get("/users/current/api-credentials");
        commit("ADD_NEW_CHANNEL", response.data[0]);
        console.log("[APIStore] Got API credentials!", response.data);
    },
    async SAVE_APIWEBHOOK_URL(context: VuexActionContext<void>, data: WebhookUrl) {
        const response =  await axios.post(`/users/current/webhook-url/${data.type}`,{url: data.url});
        console.log("[APIStore] Saved webhook url for device verification!", response.status);
        return response;
    },
    async FETCH_APIWEBHOOK_URL({commit}: VuexActionContext<DeviceVerificationUrlUpdate>, type: string) {
        console.log("type", WEBHOOK_TYPES[type as keyof typeof WEBHOOK_TYPES]);
        const response =  await axios.get(`/users/current/webhook-url/${WEBHOOK_TYPES[type as keyof typeof WEBHOOK_TYPES]}`);
        console.log(`[APIStore] Fetched webhook url for ${type}!`, response.status);
        if(response.data.length > 0) {
            commit(`ADD_${type.replace("_", "")}_URL`, response.data[0].url);
        }
    }
};
