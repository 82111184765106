import axios from "@/util/axios";
import {VuexActionContext} from "@/store/index";
import {User} from "@/interfaces/entities/User";
import {Address} from "@/interfaces/entities/Address";
import {UpdateUserRequest} from "@/interfaces/httpRequests/UpdateUserRequest";
import { Account } from "@/interfaces/entities/Account";
import { GetAccountDetailsResponse } from "@/interfaces/httpResponses/GetAccountDetailsResponse";

export interface UserState {
    user: User;
    account: Account;
}

export const userState: UserState = {
    user: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        taxId: "",
        addresses: []
    },

    account:{
        id: "",
        login: "",
        firstName: "",
        lastName: "",
        email: "",
        imageUrl: "",
        activated: false,
        langKey: "",
        createdBy: "",
        createdDate: "",
        lastModifiedBy: "",
        lastModifiedDate: "",
        authorities: [],
        agentId: "",
        customerEmail: "",
        companyName: "",
        realmName: ""
    }
};

export const userMutations = {
    SET_ACCOUNT_DETAILS(state: UserState, account: Account) {
        state.account = account;
    }
};

export const userActions = {

    /**
     * After login on Keycloak we need to call this in order to get the user
     * on the equity server.
     */
    async FETCH_ACCOUNT({commit}: VuexActionContext<Account>): Promise<void> {
        const response: GetAccountDetailsResponse = await axios.get("/account");
        console.log("[userStore] Got account: ", response.data);
        commit("SET_ACCOUNT_DETAILS", response.data);
    },

    async FETCH_ACCOUNT_DETAILS(): Promise<User> {
        const response = await axios.get("/users/current");
        return response.data;
    },

    async UPDATE_ACCOUNT(context: VuexActionContext<UserState>, payload: UpdateUserRequest): Promise<UserState> {
        return await axios.put("/users/current", payload);
    }
};
