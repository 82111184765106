







































































import {Component, Vue} from "vue-property-decorator";
import StoreLayout from "@/components/layouts/StoreLayout.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import {CustomerProfile} from "@/interfaces/entities/CustomerProfile";
import {EsimProfile} from "@/interfaces/entities/EsimProfile";
import TableList from "@/components/partials/TableList.vue";

interface ShareTokenData {
    sub: string;
    exp: number;
}

@Component({
    components: {
        TableList,
        TDSSpinner,
        StoreLayout,
        Inputfield
    }
})
export default class ESIMInstallation extends Vue {
    private profileDetails: CustomerProfile | null = null;
    private availableEsim: EsimProfile | undefined = undefined;
    private showInfo: boolean = false;
    private tableColumnsRemainingBalance: object = {
        expiration: {label: this.$t("tableHeader.expirationDate") as string, width: "150px", align: "left"},
        remaining: {label: this.$t("tableHeader.remaining") as string, width: "150px"},
        package: {label: this.$t("tableHeader.dataPackage") as string},
        purchased: {label: this.$t("tableHeader.purchased") as string, width: "120px"},
        price: {label: this.$t("tableHeader.price") as string, width: "150px", align: "right"}
    };
    private tableColumnsRemainingBalanceMobile: object = {
        expiration: {label: this.$t("tableHeader.expirationDate") as string, width: "175px", align: "left"},
        remaining: {label: this.$t("tableHeader.remaining") as string, align: "left"}
    }
    private tableColumnsDevices: object = {
        date: {label: this.$t("tableHeader.installationDate") as string, align: "left"},
        status: {label: this.$t("tableHeader.status") as string},
        link: {label: "", align: "right"}
    };
    private tableColumnsDevicesMobile: object = {
        date: {label: this.$t("tableHeader.installationDate") as string, width: "175px", align: "left"},
        status: {label: this.$t("tableHeader.status") as string, align: "left"}
    };

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        this.fetchCustomerData();
    }

    private async fetchCustomerData() {
        const token = this.$route.query.token ?? "";
        if (!this.isValidShareToken(token)) {
            this.$toastr.error( this.$t("esimInstallation.error.tokenInvalid") ).then(() =>location.reload());
        } else {
            try {
                this.profileDetails = await this.$store.dispatch("FETCH_CUSTOMER_DATA", token);
                this.availableEsim = this.profileDetails?.relatedEsims.find((eSim: EsimProfile) => eSim.active && eSim.state.toLowerCase() === "released");
                this.showInfo = true;
            }catch (e) {
                this.$toastr.error( this.$t("esimInstallation.error.tokenInvalid") ).then(() =>location.reload());
            }
        }
    }

    private isValidShareToken(token: string | (string | null)[]): boolean {
        if (Array.isArray(token)) throw new Error("Token query param is not a string!");
        if (!token) return false;
        try {
            const {sub: iccid, exp: expiresAt}: ShareTokenData = JSON
                .parse(atob(token.split(".")[1]));
            if (Date.now() > expiresAt * 1000) {
                console.log("[ESIMInstallationPage] Share token is expired!");
                return false;
            }
            console.log(`[ESIMInstallationPage] Data from token:\n\tICCID='${iccid}'\n\texpiresAt='${new Date(expiresAt * 1000)}'`);
            return true;
        } catch (e) {
            console.log("[ESIMInstallationPage] Error on parsing token: ", e);
            return false;
        }
    }

    private openUrl(url: string) {
        if(url) window.open(url, "_self");
    }
}
