import Vue from "vue";
import VueI18n from "vue-i18n";
import localeEn from "./locale-en.json";
import localeDe from "./locale-de.json";
import themeLocaleEn from "../../temp/i18n/locale-en.json";
import themeLocaleDe from "../../temp/i18n/locale-de.json";

Vue.use(VueI18n);

//  Getting the active language from the clients browser...
let locale = "en"; //window.navigator.language.substring(0, 2).toLowerCase();
if (!["de", "en"].includes(locale)) {
    locale = "en";
}
export const browserLocale = locale;

export default new VueI18n({
    locale,
    messages: {
        de: {
            ...localeDe,
            ...themeLocaleDe // theme wordings are overwriting the default wordings...
        },
        en: {
            ...localeEn,
            ...themeLocaleEn
        }
    }
});
