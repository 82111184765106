

















import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { LegalContent } from "@/interfaces/entities/LegalContent";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
    components: { TDSButton, TDSSpinner, DefaultLayout }
})
export default class TermsAndConditionsPage extends Vue {
    private tcs: LegalContent | null = null;
    private isLoading: boolean = false;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("tandc.title");
        this.fetchTCs();
    }

    private async fetchTCs() {
        this.isLoading = true;
        try {
            this.tcs = await this.$store.dispatch("FETCH_TERMS_AND_CONDITIONS");
        } catch (e) {
            console.error("[Terms & Conditions] Failed fetching Terms & Conditions");
            window.open("https://www.dentwireless.com/tc");
            this.$router.replace("/");
        } finally {
            this.isLoading = false;
        }
    }
}
