



































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TableList from "@/components/partials/TableList.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import PackageCard from "@/components/partials/PackageCard.vue";
import PricingBanner from "../partials/PricingPage/PricingBanner.vue";
import { Product } from "@/interfaces/entities/Product";
import { emojiFlag } from "@/util/emojiFlag";
import { Filter } from "@/interfaces/entities/Filter";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {
        FilterPanel,
        TDSModal,
        Inputfield,
        TableList,
        DefaultLayout,
        TDSButton,
        PackageCard,
        PricingBanner,
        TDSSpinner
    }
})
export default class AddPackages extends Vue {
    private isLoading: boolean = true;
    private cartBoxIsOpen: boolean = false;
    // private bulkDiscount: BulkDiscount | null = null;
    // private bulkDiscountInfo: BulkDiscountInfo[] = [];
    private selectedAmounts: { [productId: string]: number } = {};
    private stepsCardVisible: boolean = false;
    private showPackageModal: boolean = false;
    private modalPackage?: Product;
    private tableColumns: object = {
        detail: { label: "", sortable: false, width: "32px", align: "center" },
        coverage: { label: this.$t("pricing.table.dataPackage") as string, sortable: true, sortBy: (product: Product) => product.countrySet },
        sizeValue: { label: this.$t("pricing.table.volume") as string, sortable: true, width: "80px", align: "right" },
        validitySize: {
            label: this.$t("pricing.table.validity") as string,
            sortable: true,
            width: "100px",
            align: "right"
        },
        price: {
            label: this.$t("pricing.table.price") as string,
            sortable: true,
            width: "120px",
            align: "right",
            sortBy: (product: Product) => (product.prices ? product.prices[0].priceValue : 0)
        },
        defaultRetailPriceInUsd: { label: this.$t("pricing.table.rrp") as string, sortable: true, width: "140px", align: "right" }
    };
    private waitForHtmlElementsTimeout?: ReturnType<typeof setTimeout> = undefined;
    private showWelcomeBonus: boolean = false;
    private filters: { [key: string]: Filter } = {
        coverage: {
            val: "countrySet",
            key: "countrySet",
            label: (item: any) => this.$options.filters?.countryName(item.countrySet),
            placeholder: "All Coverage",
            icon: "mobile-data-grey24"
        },
        validity: {
            val: "validitySize",
            key: "validitySize",
            label: (item: any) => item.validitySize + " " + item.validityUnit,
            placeholder: "All Validities"
        },
        volumes: {
            val: "sizeValue",
            key: "sizeValue",
            label: (item: any) => item.sizeValue,
            placeholder: "All Volumes",
            icon: "pricing-esim"
        }
    };
    private filteredItems: Product[] = [];

    get availableProducts(): Array<Product> {
        const availableProducts = this.$store.state.availableProducts;

        // Sort the available products by the name of the countrySet
        availableProducts.sort((a: Product, b: Product) => {
            const countryNameA = this.$options.filters?.countryName(a.countrySet);
            const countryNameB = this.$options.filters?.countryName(b.countrySet);

            // Check if either a or b is "Worldwide"
            if (countryNameA === "Worldwide" && countryNameB !== "Worldwide") {
                return -1; // "Worldwide" comes first
            } else if (countryNameB === "Worldwide" && countryNameA !== "Worldwide") {
                return 1; // "Worldwide" comes first
            }

            const aLength = a.includedCountries?.length || 0;
            const bLength = b.includedCountries?.length || 0;

            // Region packages are sorted by name
            if (aLength > 1 && bLength > 1) {
                return countryNameA.localeCompare(countryNameB);
            }

            // If one is a region package and the other is not, the region package comes first
            if (aLength !== bLength) {
                return aLength > bLength ? -1 : 1;
            }

            // If neither is "Worldwide", sort alphabetically
            // Use localeCompare to ensure correct sorting even with special characters
            return countryNameA.localeCompare(countryNameB);
        });

        return availableProducts;
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("addPackages.pageTitle");
        this.$store.dispatch("FETCH_AVAILABLE_PRODUCTS").finally(() => {
            this.isLoading = false;
        });
    }

    @Watch("availableProducts")
    onAvailableProductsChange(products: Array<Product>) {
        products.forEach((p: Product) => {
            if (!this.selectedAmounts[p.id]) {
                this.$set(this.selectedAmounts, p.id, 0);
            }
        });
    }

    @Watch("isMobile")
    isMobileChanged() {
        if (this.showWelcomeBonus) this.cartBoxIsOpen = true;
        else this.cartBoxIsOpen = false;
    }

    private getEmojiFlag(countryCode: string): string {
        return emojiFlag(countryCode, "🌏");
    }

    private openDetailModal(item: Product) {
        this.modalPackage = item;
        this.showPackageModal = true;
    }

    private closeDetailModal() {
        this.modalPackage = undefined;
        this.showPackageModal = false;
    }
}
