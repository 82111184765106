












































































































































































































































































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class PackageCard extends Vue {
    @Prop({required: true}) type!: string;
    @Prop({required: true}) color!: string;
    @Prop({required: true}) coverage!: string;
    @Prop({required: true}) volume!: string;
    @Prop() name!: string;
    @Prop({required: true}) price!: number;
    @Prop({required: true}) validity!: string;

    get _coverage(): string {
        return this.splitString(this.coverage, 10, 30, 20);
    }

    get _name(): string {
        return this.splitString(this.name, 24, 0,24);
    }

    get _validity(): string {
        return this.validity.replace("d", "D");
    }

    splitString(str: string, x: number, y1: number, y2: number) {
        let dy = y1;
        if(str.replace(" ", "").length > 14) {
            const split = str.split(" ");
            let returnHtml = "";
            let line = "";
            split.forEach((s: string) => {
                if (line && line.replace(" ", "").length + s.length > 13) {
                    returnHtml += "<tspan x='" + x + "' dy='" + dy + "'>" + line.trim() + "</tspan>";
                    line = "";
                    dy = y2;
                }
                if (s.length > 13) {
                    returnHtml += "<tspan x='" + x + "' dy='" + dy + "'>" + s.substr(0, 13) + "</tspan>";
                    line = s.substr(14) + " ";
                    dy = y2;
                } else line += s + " ";
            });
            if(line) returnHtml += "<tspan x='" + x + "' dy='" + dy + "'>" + line.trim() + "</tspan>";
            return returnHtml;
        } else {
            return "<tspan x='" + x + "' dy='" + dy + "'>" + str + "</tspan>";
        }
    }
}
