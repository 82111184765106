





















































import {Component, Prop, Vue} from "vue-property-decorator";
import TDSModal from "../../../../../components/partials/TDSModal/TDSModal.vue";
import Inputfield from "../../../../../components/partials/Inputfield.vue";
import TDSButton from "../../../../../components/common/TDSButton.vue";

@Component({
    components: {TDSModal, Inputfield, TDSButton}
})
export default class CustomPaymentOption extends Vue {
    private password: string = "";
    private twoFactorCode: string = "";

    @Prop({type: Boolean, default: false})
    private inProgress!: boolean;

    passwordValidator(password: string) {
        return password;
    }

    twoFactorCodeValidator(twoFactorCode: string) {
        return twoFactorCode && twoFactorCode.replace(/\D/g, "") === twoFactorCode;
    }

    get inputValid() {
        return this.password && this.twoFactorCode;
    }

    login() {
        if (!(this.$refs.passwordInput as Inputfield).submit() || !(this.$refs.twoFactorInput as Inputfield).submit()) {
            return console.log("[DENTPayment] Input invalid");
        }
        this.$emit("next", {
            code: this.twoFactorCode,
            password: this.password
        });
    }

}
