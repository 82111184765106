
































































import {Component, Vue} from "vue-property-decorator";
import TableList from "@/components/partials/TableList.vue";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
    components: {
        TableList,
        TDSButton
    }
})
export default class StoreDetails extends Vue {

    isOpeningLink: boolean = false;

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("yourStore.channels.pageTitle");
    }

    async openInternalLink(path: string, inNewTab: boolean = false) {
        this.isOpeningLink = true;
        if (inNewTab) {
            const routeData = this.$router.resolve(path);
            window.open(routeData.href, "_blank");
        } else {
            await this.$router.push(path);
        }
        this.isOpeningLink = false;
    }
}
