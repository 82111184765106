















import TDSButton from "@/components/common/TDSButton.vue";
import {Component, Vue} from "vue-property-decorator";

@Component({
    components: {
        TDSButton
    }
})

export default class PricingBanner extends Vue {
    public isModalOpened: boolean = false;

    get discountRate() {
        return this.$store.state.discountRate;
    }

    get userEmail(){
        return this.$store.state.account.email;
    }

    get userName(){
        return this.$store.state.account.firstName;
    }

    public redirectToForm() {
        window.open(`https://survey.typeform.com/to/UVOV5OsQ#email=${this.userEmail}&name=${this.userName}&pricing_group=${this.discountRate}&discount_request=False`, "_blank");
    }
}
