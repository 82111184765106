



















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TDSModal from "../../../../../components/partials/TDSModal/TDSModal.vue";
import Inputfield from "../../../../../components/partials/Inputfield.vue";
import TDSButton from "../../../../../components/common/TDSButton.vue";

@Component({
    components: {TDSModal, Inputfield, TDSButton}
})
export default class CustomPaymentOption extends Vue {
    private phoneNumber: string = window.localStorage.getItem("dentLogin") ?? "";
    private dentExchangeLink: string = process.env.VUE_APP_DENT_EXCHANGE_LINK ?? "";

    @Prop({ type: Boolean, default: false })
    private inProgress!: boolean;

    @Watch("phoneNumber")
    phoneNumberChanged() {
        if (this.phoneNumber.length === 1 && Number.isInteger(Number(this.phoneNumber))) {
            this.phoneNumber = "+" + this.phoneNumber;
        }
    }

    phoneNumberValidator(phoneNumber: string) {
        return phoneNumber && "+" + phoneNumber.replace(/\D/g, "") === phoneNumber;
    }

    get inputValid() {
        return this.phoneNumber;
    }

    login() {
        if (!(this.$refs.phoneNumberInput as Inputfield).submit()) {
            return console.log("[DENTPayment] Phone number invalid");
        }
        window.localStorage.setItem("dentLogin", this.phoneNumber);
        this.$emit("next", this.phoneNumber);
    }

}
