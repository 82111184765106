import {SoldItem} from "@/interfaces/entities/SoldItem";
import TDSDate, {TDSDateUnit} from "@/util/TDSDate";
import axios from "@/util/axios";
import {ActivatedItem} from "@/interfaces/entities/ActivatedItem";
import {VuexActionContext} from "@/store/index";
import {GetChartCandlesResponse} from "@/interfaces/httpResponses/GetChartCandlesResponse";
import {ChartCandleSet, ChartCandlesResponse} from "@/interfaces/entities/ChartCandleSet";
import {tools} from "@/util/tools";
import {EsimDetails} from "@/interfaces/entities/EsimDetails";

export interface FetchCandleInfo extends FetchSoldItemInfo{
    granularity: string;
}

export interface FetchSoldItemInfo {
    start: string;
    end: string;
    userFilterType?: string;
    userFilterValue?: string;
}

export interface SoldItemsState {
    soldItems: SoldItem[];
}

export const soldItemsState: SoldItemsState = {
    soldItems: []
};
export const soldItemsMutations = {
    SET_SOLD_ITEMS(state: SoldItemsState, soldItems: Array<SoldItem>) {
        state.soldItems = soldItems.filter((itemA: SoldItem, indexA: number, arr: Array<SoldItem>) => {
            const indexB = arr.findIndex((itemB: SoldItem) => itemA.uid === itemB.uid);
            return indexB === indexA;
        });
    }
};

export const soldItemsActions = {

    async FETCH_REVENUE_CHART_CANDLES(context: VuexActionContext<ChartCandleSet>, info: FetchCandleInfo): Promise<ChartCandlesResponse> {
        const url = `/analysis/revenue?from=${info.start}&to=${info.end}&currency=USD&granularity=${info.granularity}`;
        console.log("[SoldItemsStore] URL for chart candles: ", url);
        const response: GetChartCandlesResponse = await axios.get(url);
        console.log("[SoldItemsStore] Got chart data: ", response.data);
        return response.data;
    },

    async FETCH_SOLD_ITEMS({commit}: VuexActionContext<Array<SoldItem>>, info: FetchSoldItemInfo|null) {
        const start = info && info.start ? info.start : new TDSDate(Date.now()).add(-3, TDSDateUnit.YEAR).format("YYYY-MM-DDTHH:mm:ss.000Z");
        const end = info && info.end ? info.end : new TDSDate(Date.now()).add(1, TDSDateUnit.DAY).format("YYYY-MM-DDTHH:mm:ss.000Z");
        const url = `/activations/activated-items?from=${start}&to=${end}${info?.userFilterValue ? "&user_identifier=" +info?.userFilterValue + "&user_identifier_key=" + info?.userFilterType : ""}`;
        const response = await axios.get(url);
        console.log("[SoldItemsStore] Got activated inventory items: ", response.data);
        const mappedData = response.data.map((soldItem: SoldItem): SoldItem => {
            soldItem.timestamp = Date.parse(soldItem.balance.activatedAt);
            soldItem.date = new TDSDate(Date.parse(soldItem.balance.activatedAt)).format("DD-MM-YYYY HH:mm");
            return soldItem;
        }).sort((itemA: SoldItem, itemB: SoldItem): number => {
            //@ts-ignore
            return itemB.timestamp - itemA.timestamp;
        });
        commit("SET_SOLD_ITEMS", mappedData);
    },

    async GET_SOLD_ITEM_BY_ID(context: VuexActionContext<ActivatedItem>, id: string) {
        const response = await axios.get("/activations/activated-items/" + id);
        console.log("[SoldItemsStore] Got activated inventory items: ", response.data);
        return response.data;
    },

    async FETCH_SOLD_ITEMS_AS_CSV(context: VuexActionContext<SoldItem[]>, info: FetchSoldItemInfo|null): Promise<string> {
        const start = info && info.start ? info.start : new TDSDate(Date.now()).add(-3, TDSDateUnit.YEAR).format("YYYY-MM-DDTHH:mm:ss.000Z");
        const end = info && info.end ? info.end : new TDSDate(Date.now()).add(1, TDSDateUnit.DAY).format("YYYY-MM-DDTHH:mm:ss.000Z");const url = `/activations/activated-items/csv-export?from=${start}&to=${end}`;
        const response: { data: string } = await axios.get(url);
        console.log("[SoldItemsStore] Got CSV export: ", response.data);
        return response.data;
    },

    async FETCH_RECEIPT(context: VuexActionContext<ActivatedItem>, url: string): Promise<void> {
        axios.get(url, {responseType: "blob"}).then((response: {data: string}) => {
            tools.saveFile(
                `gigastore-receipt-${Math.floor(Date.now() / 1000)}.pdf`,
                response.data
            );
        });
    },

    async FETCH_ESIM_DETAILS(context: VuexActionContext<EsimDetails>, iccid: string): Promise<void> {
        const response = await axios.get(`/esim/${iccid}/status/connectivity`);
        console.log("[SoldItemsStore] Got ESIM details: ", response.data);
        return response.data;
    },

    async REFUND_SOLD_ITEM(context: VuexActionContext<ActivatedItem>, activatedItemUID: string) {
        console.log("[SoldItemsStore] Refunding activated item: ", activatedItemUID);
        const response = await axios.post("/activations/activated-items/" + activatedItemUID + "/refund");
        console.log("[SoldItemsStore] Refunding activated item result: ", response.data);
        return response.data;
    }
};
