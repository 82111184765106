










import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TDSSpinner extends Vue {
    @Prop({type: Number, default: 16}) size!: number;
    @Prop({type: Number, default: 2}) borderWidth!: number;
    @Prop({type: String, default: ""}) color!: string;
    @Prop({type: String, default: "0 auto"}) margin!: string;
    @Prop({type: Boolean, default: true}) fadeIn!: boolean;
}
