import { render, staticRenderFns } from "./CardContentGrey.vue?vue&type=template&id=ea96a626&scoped=true&"
import script from "./CardContentGrey.vue?vue&type=script&lang=ts&"
export * from "./CardContentGrey.vue?vue&type=script&lang=ts&"
import style0 from "./CardContentGrey.vue?vue&type=style&index=0&id=ea96a626&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea96a626",
  null
  
)

export default component.exports