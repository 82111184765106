






























import { Component, Prop, Vue } from "vue-property-decorator";
import TDSModal from "../../components/partials/TDSModal/TDSModal.vue";
import TDSButton from "../../components/common/TDSButton.vue";
import TDSInputField from "@/components/common/TDSInputField.vue";

@Component({
    components: { TDSModal, TDSInputField, TDSButton }
})
export default class AddCustomerEmailModal extends Vue {
    @Prop({ required: true }) customerUid!: string;

    private newEmail: string = "";
    private newEmailValid: boolean = true;

    private async setMail() {
        if (!this.newEmailValid) return;
        try {
            const response = await this.$store.dispatch("UPDATE_CUSTOMER", {
                customerEmail: this.newEmail,
                customerUid: this.customerUid
            });
            console.log(response);
        } catch (e) {
            this.$emit("close");
            if (e.response.data.title === "Email address already in use!") this.$toastr.error("Failed adding email to customer. Email address is already in use.");
            else this.$toastr.error("Failed adding email to customer. Please try again later.");
        }
        this.$emit("reload");
    }

    private closeModal() {
        this.$emit("close");
    }
}
