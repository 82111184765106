import Axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import store from "../store";
import {browserLocale} from "@/i18n";

const PUBLIC_API_ROUTES = [
    "/esim/profile"
];

function isPublicRoute(config: AxiosRequestConfig) {
    return PUBLIC_API_ROUTES.some((routePrefix: string): boolean => {
        return config.url?.startsWith(routePrefix) ?? false;
    });
}

const axiosConfig = {
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000
};

console.log("[axios] Server API base URL is: ", process.env.VUE_APP_API_URL);

const axiosInstance: AxiosInstance = Axios.create(axiosConfig);

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig): AxiosRequestConfig {
    config.headers["Locale"] = browserLocale;
    config.headers["Device-TimeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (store.state.auth && !isPublicRoute(config)) {
        config.headers["Authorization"] = `Bearer ${store.state.auth}`;
    }
    return config;
}, function (error: AxiosError) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response: AxiosResponse): AxiosResponse {
    return response;
}, function (error: AxiosError) {

    // API returns, that the auth token is invalid, but we have some in the store
    // ... just reload to retrieve a new access token
    if (error?.response?.status === 401 && store.state.auth) {
        window.location.reload();
    }
    return Promise.reject(error);
});

export default axiosInstance;
