















































































































import { Component, Vue } from "vue-property-decorator";
import StoreLayout from "@/components/layouts/StoreLayout.vue";
import QRCode from "qrcode";
import Inputfield from "@/components/partials/Inputfield.vue";
import { GetESIMDataResponse } from "@/interfaces/httpResponses/GetESIMDataResponse";
import { tools } from "@/util/tools";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

interface ShareTokenData {
    sub: string;
    exp: number;
}

@Component({
    components: {
        TDSSpinner,
        StoreLayout,
        Inputfield
    }
})
export default class ESIMInstallation extends Vue {
    private profileDetails: any = {
        activationCode: "",
        iccid: "",
        volume: 0,
        volumeUnit: "",
        validity: 0,
        validityUnit: "",
        hasValidity: true,
        customerProfileUrl: null
    };
    private showInfo: boolean = false;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        this.fetchESIMData().then(this.renderQRCode);
    }

    private async fetchESIMData() {
        const token = this.$route.query.token ?? "";
        if (!this.isValidShareToken(token)) {
            this.$toastr.error(this.$t("esimInstallation.error.tokenInvalid")).then(() => location.reload());
        } else {
            try {
                const response: GetESIMDataResponse = await this.$store.dispatch("FETCH_ESIM_DATA", token);
                const eSIMProfile = response.data.esimProfile;
                const balance = response.data.balances[0];
                this.$set(this.profileDetails, "activationCode", eSIMProfile.activationCode);
                this.$set(this.profileDetails, "iccid", eSIMProfile.iccid);
                this.$set(this.profileDetails, "volume", balance.size.sizeValue);
                this.$set(this.profileDetails, "volumeUnit", balance.size.sizeUnit);
                this.$set(this.profileDetails, "validity", balance.validitySize);
                this.$set(this.profileDetails, "validityUnit", balance.validityUnit);
                this.$set(this.profileDetails, "customerProfileUrl", response.data.customerProfileUrl);
                // this.$set(this.profileDetails, "hasValidity", !balance.validityUnlimited);

                this.showInfo = true;
            } catch (e) {
                this.$toastr.error(this.$t("esimInstallation.error.tokenInvalid")).then(() => location.reload());
            }
        }
    }

    private isValidShareToken(token: string | (string | null)[]): boolean {
        if (Array.isArray(token)) throw new Error("Token query param is not a string!");
        if (!token) return false;
        try {
            const { sub: iccid, exp: expiresAt }: ShareTokenData = JSON.parse(atob(token.split(".")[1]));
            if (Date.now() > expiresAt * 1000) {
                console.log("[ESIMInstallationPage] Share token is expired!");
                return false;
            }
            console.log(`[ESIMInstallationPage] Data from token:\n\tICCID='${iccid}'\n\texpiresAt='${new Date(expiresAt * 1000)}'`);
            return true;
        } catch (e) {
            console.log("[ESIMInstallationPage] Error on parsing token: ", e);
            return false;
        }
    }

    public async copyToClipboard(code: string) {
        await tools.copyToClipboard(code);
        this.$toastr.success(this.$t("esimInstallation.success.codeCopied"), true, true);
    }

    private renderQRCode() {
        if (!this.profileDetails.activationCode) return;
        QRCode.toCanvas(
            this.$refs.qrCode,
            this.profileDetails.activationCode,
            {
                width: (this.$refs.qrCode as HTMLCanvasElement).width,
                margin: 0
            },
            (error: any) => {
                if (error) console.error("Failed rendering QR code: ", error);
            }
        );
    }
}
