import axios from "@/util/axios";
import {InventoryItem} from "@/interfaces/entities/InventoryItem";
import {VuexActionContext} from "@/store/index";
import {UpdateInventoryRequest} from "@/interfaces/httpRequests/UpdateInventoryRequest";
import {ActivateInventoryItemRequest} from "@/interfaces/httpRequests/ActivateInventoryItemRequest";
import {ActivateDataResponse} from "@/interfaces/httpResponses/ActivateDataResponse";
import {UpdateInventoryItemRequest} from "@/interfaces/httpRequests/UpdateInventoryItemRequest";

export const inventoryActions = {

    async FETCH_INVENTORY(): Promise<Array<InventoryItem>> {
        const response = await axios.get("/products/inventory");
        console.log("[InventoryStore] Got inventory: ", response.data);
        return response.data.items.sort((itemA: InventoryItem, itemB: InventoryItem) => {
            if(itemB.name > itemA.name) return 1;
            if(itemB.name < itemA.name) return -1;
            return 0;
        });
    },

    async UPDATE_INVENTORY(context: VuexActionContext<void>, payload: UpdateInventoryRequest) {
        const requestBody = payload;
        const response = await axios.put("/products/inventory/", requestBody);
        console.log("[InventoryStore] Updated inventory: ", response.data, requestBody);
    },

    async FETCH_INVENTORY_ITEM(context: VuexActionContext<void>, id: string): Promise<InventoryItem> {
        const response = await axios.get("/products/inventory/" + id);
        console.log("[InventoryStore] Got inventory: ", response.data);
        return response.data;
    },

    async UPDATE_INVENTORY_ITEM(context: VuexActionContext<void>, payload: UpdateInventoryItemRequest) {
        const requestBody = {
            name: payload.name,
            retailPrice: payload.retailPrice
        };
        const response = await axios.put("/products/inventory/" + payload.inventoryItemId, requestBody);
        console.log("[InventoryStore] Updated inventory: ", response.data, requestBody);
    },

    async ACTIVATE_INVENTORY_ITEM(context: VuexActionContext<void>, payload: ActivateInventoryItemRequest): Promise<ActivateDataResponse> {
        const response = await axios.post("/activations/register", payload);
        console.log("[InventoryStore] Activated inventory item: ", response.data);
        return response.data;
    },

    async TOPUP_INVENTORY_ITEM(context: VuexActionContext<void>, payload: ActivateInventoryItemRequest): Promise<ActivateDataResponse> {
        const response = await axios.post("/activations/top-up", payload);
        console.log("[InventoryStore] Activated inventory item: ", response.data);
        return response.data;
    },

    async FETCH_CREDIT_INFOS() {
        const response: any = await axios.get("/users/current/credit");
        console.log("[InventoryStore] Got credit balance: ", response.data);
        return response.data;
    },

    async SET_AUTO_TOPUP(context: VuexActionContext<void>, payload: UpdateInventoryRequest) {
        const response: any = await axios.post("/users/current/credit/auto-top-up", payload);
        console.log("[InventoryStore] Set auto topup: ", response.data);
    }
};
