



























import {Component, Vue, Watch} from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TDSButton from "@/components/common/TDSButton.vue";

@Component({
    components: {DefaultLayout, TDSButton}

})
export default class Checkout extends Vue {

    private isPendingPayment: boolean = false;

    created() {
        this.$store.commit("SET_PREVIOUS_VIEW", "/data-packages");
        this.isPendingPayment = this.$route.query.pending === "true";
    }

    @Watch("$route.params")
    paramsChanged() {
        this.isPendingPayment = this.$route.query.pending === "true";
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }
}
