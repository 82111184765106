


























import {Component, Prop, Vue} from "vue-property-decorator";
    import StoreNavigationBar from "../partials/StoreNavigationBar.vue";
    import StoreFooter from "../partials/StoreFooter.vue";
    import DefaultHeader from "@/components/partials/DefaultHeader.vue";

@Component({
  components: {
    DefaultHeader,
      StoreFooter,
    StoreNavigationBar
  }
})
    export default class DefaultLayout extends Vue {
        @Prop({type: Boolean, default: true}) showNav!: boolean;
        @Prop({type: Boolean, default: true}) showFooter!: boolean;
        @Prop({type: Boolean, default: false}) shareButton!: boolean;
        @Prop({type: String, default: null}) profileLink?: string;
        get previousView(): boolean {
            return this.$store.state.previousView;
        }

        get isMobile(): boolean {
            return this.$store.state.isMobile;
        }

        get headerHeight(): string {
            return this.$store.state.headerHeight;
        }
    }
