

















import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { LegalContent } from "@/interfaces/entities/LegalContent";

@Component({
    components: { TDSSpinner, DefaultLayout }
})
export default class PrivacyPage extends Vue {
    private pp: LegalContent | null = null;
    private isLoading: boolean = false;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("privacy.title");
        this.fetchTCs();
    }

    private async fetchTCs() {
        this.isLoading = true;
        try {
            this.pp = await this.$store.dispatch("FETCH_PRIVACY_POLICY");
        } catch (e) {
            console.error("[Privacy Policy] Failed fetching Privacy Policy");
            window.open("https://www.dentwireless.com/privacy");
            this.$router.replace("/");
        } finally {
            this.isLoading = false;
        }
    }
}
