




    import {Component, Prop, Vue} from "vue-property-decorator";

@Component
    export default class Triangle extends Vue {
  @Prop({required: true}) gradient!: string;
  @Prop({required: true}) transform!: string;
    }

