





























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import BalanceHeader from "@/components/partials/BalanceHeader.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { APICredential } from "@/interfaces/entities/APICredential";

@Component({
    components: { TDSSpinner, TDSModal, TDSButton, Inputfield, DefaultLayout, BalanceHeader }
})
export default class SalesChannel extends Vue {
    private webhooksModalOpen: boolean = true;
    private editModalOpen: boolean = false;
    private fsModalOpen: boolean = false;
    private channelName: string = "";
    private selectedChannel!: APICredential;
    private mobileSwitchOpen: boolean = false;
    private selectedStep: string = "retrieveToken";
    private isLoading: boolean = true;
    private baseUrl: string | undefined = process.env.VUE_APP_API_URL;

    get steps(): { [key: string]: string } {
        let url: string = "https://api.giga.store";
        if (this.baseUrl?.includes("llamaapi")) url = "https://apigw.llamaapi.io";
        if (this.baseUrl?.includes("dromedaryapi")) url = "https://apigw.dromedaryapi.io";
        return {
            retrieveToken: `curl --location --request POST '${url}/reseller/authenticate'
--header 'Authorization: Basic ${btoa(this.selectedChannel?.clientId + ":" + this.selectedChannel?.clientSecret)}'`,
            getInventory: `curl --location --request GET '${url}/gigastore/products/inventory'
--header 'Authorization: Bearer eyJhbGciO...YOUR_ACCESS_TOKEN...`,
            activatePackage: `curl --location --request POST '${url}/gigastore/activations/register'
--header 'Authorization: Bearer eyJhbGc...YOUR_ACCESS_TOKEN...'
--header 'Content-Type: application/json'
--data-raw '{
    "inventoryItemId": "00e3e46e-faa5-465a-9321-2f9b719ce79c",
    "metatag": "Comment for reseller..."
}'`,
            // eslint-disable-next-line quotes
            dentBranding: '<a href="https://giga.store><img src="https://giga.store/branding/poweredbydent.svg></a>'
        };
    }

    get channels() {
        return this.$store.state.apiChannels;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("apiDashboard.pageTitle");
        this.fetchCredentials();
        this.selectedChannel = this.channels.length > 0 ? this.channels[0] : [];
    }

    mounted() {
        this.calcTextareaHeight();
    }

    @Watch("isMobile")
    mobileChanged() {
        this.calcTextareaHeight();
    }

    @Watch("selectedStep")
    selectedStepChanged() {
        this.$nextTick(() => {
            this.calcTextareaHeight();
        });
    }

    @Watch("fsModalOpen")
    fsModalOpenChanged(value: boolean) {
        if (value) {
            this.$nextTick(() => {
                this.calcTextareaHeight();
            });
        }
    }

    @Watch("channels")
    channelsChanged() {
        this.selectedChannel = this.channels.length > 0 ? this.channels[0] : [];
    }

    private async fetchCredentials() {
        this.isLoading = true;
        try {
            await this.$store.dispatch("FETCH_API_CREDENTIALS");
        } catch (e) {
            console.log("[API Dashboard] Failed fetching API credentials");
        } finally {
            this.isLoading = false;
        }
    }

    private calcTextareaHeight() {
        const textarea = this.$refs.request as HTMLTextAreaElement;
        if (!textarea) return;
        const lines = textarea.value.split(/\n/g) || [];
        // lines x line-height + padding + border
        const maxChars = this.isMobile ? 33 : 100;
        let numberOfLines = 0;
        lines.forEach((line: string) => {
            numberOfLines += Math.ceil(line.length / maxChars);
        });
        textarea.style.height = (this.isMobile ? numberOfLines * 16 + 70 : numberOfLines * 14 + 46) + "px";
    }

    // NEEDED LATER
    private addChannel() {
        this.$store.commit("ADD_NEW_CHANNEL");
    }

    // NEEDED LATER
    private editChannel() {
        this.channelName = this.selectedChannel?.name || "";
        this.editModalOpen = true;
    }

    // private saveName() {
    //     if (!this.channelName || !this.selectedChannel || this.selectedChannel.name === this.channelName) return;
    //     this.$store.commit("SET_CHANNEL_NAME", {id: this.selectedChannel.id, name: this.channelName});
    //     this.editModalOpen = false;
    // }

    // // NEEDED LATER
    // private removeChannel() {
    //     if (!this.selectedChannel) return;
    //     this.$store.commit("REMOVE_CHANNEL", this.selectedChannel.id);
    //     this.selectedChannel = this.channels ? this.channels[0] : [];
    // }

    private copyRequest(text: string) {
        const toastr = this.$toastr;
        const success = this.$t("components.inputfield.copied");
        navigator.clipboard.writeText(text.replace(/[\r\n]/gm, " ")).then(function() {
            toastr.success(success, true, true);
        });
    }

    private nextStep() {
        if (this.selectedStep === "retrieveToken") this.selectedStep = "getInventory";
        else if (this.selectedStep === "getInventory") this.selectedStep = "activatePackage";
        else if (this.selectedStep === "activatePackage") this.selectedStep = "dentBranding";
    }

    private prevStep() {
        if (this.selectedStep === "getInventory") this.selectedStep = "retrieveToken";
        else if (this.selectedStep === "activatePackage") this.selectedStep = "getInventory";
        else if (this.selectedStep === "dentBranding") this.selectedStep = "activatePackage";
    }

    private toggleMobileOpen() {
        if (this.channels.length < 2) return;
        this.mobileSwitchOpen = !this.mobileSwitchOpen;
    }
}
