import { render, staticRenderFns } from "./AddCustomerEmailModal.vue?vue&type=template&id=359dda19&scoped=true&"
import script from "./AddCustomerEmailModal.vue?vue&type=script&lang=ts&"
export * from "./AddCustomerEmailModal.vue?vue&type=script&lang=ts&"
import style0 from "./AddCustomerEmailModal.vue?vue&type=style&index=0&id=359dda19&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359dda19",
  null
  
)

export default component.exports