import _Vue from "vue";
import {CartItem} from "@/interfaces/entities/CartItem";
import store from "../store";
import {CartPrice} from "@/interfaces/entities/CartPrice";

interface Callback {
    (items: CartItem[], cartPrice: CartPrice | null, error: string): void;
}

interface CartPlugin {

    getAllItems(): CartItem[];

    onChange(name: string, callback: Callback): void;

    removeListener(name: string): void;

    addItem(item: CartItem): void;

    changeAmount(id: string, newAmount: number): void;

    emptyCart(): void;

    removeItem(id: string): void;

    fetchCartPrice(): Promise<CartPrice>;

    fetchCartPriceOfItems(items: CartItem[]): Promise<CartPrice>;

    preAddItemToCart(item: CartItem): CartItem[];

    _broadcastChange(items: Array<CartItem>): void;

    _listeners: { [name: string]: Callback };
}

export function Cart(Vue: typeof _Vue): void {

    const cartPlugin: CartPlugin = {

        onChange(name: string, callback: Callback) {
            cartPlugin._listeners[name] = callback;
        },

        removeListener(name: string) {
            delete cartPlugin._listeners[name];
        },

        getAllItems(): CartItem[] {
            try {
                const itemsString: string = localStorage.products;
                const items: CartItem[] = JSON.parse(itemsString);
                if (Array.isArray(items)) return items;
                else return [];
            } catch (e) {
                return [];
            }

        },

        addItem(item: CartItem): void {
            const items: CartItem[] = cartPlugin.getAllItems();
            const itemIndex: number = items.findIndex((d: CartItem) => d.id === item.id);
            if (itemIndex !== -1) {
                items[itemIndex].amount += item.amount;
            } else {
                items.push(item);
            }
            localStorage.products = JSON.stringify(items);
            cartPlugin._broadcastChange(items);
        },

        changeAmount(id: string, newAmount: number): void {
            const items: CartItem[] = cartPlugin.getAllItems();
            items[items.findIndex((d: CartItem) => d.id === id)].amount = newAmount;
            localStorage.products = JSON.stringify(items);
            cartPlugin._broadcastChange(items);
        },

        emptyCart(): void {
            localStorage.removeItem("products");
            cartPlugin._broadcastChange([]);
        },

        removeItem(id: string): void {
            const items: CartItem[] = cartPlugin.getAllItems();
            items.splice(items.findIndex((d: CartItem) => d.id === id), 1);
            localStorage.products = JSON.stringify(items);
            cartPlugin._broadcastChange(items);
        },

        async fetchCartPrice(): Promise<CartPrice> {
            const items: CartItem[] = cartPlugin.getAllItems();
            if (items?.length ?? 0 > 0) {
                return await store.dispatch("CALCULATE_CART_PRICE", items);
            }
            return {
                items: [],
                totalPrices: [{
                    priceValue: 0,
                    currencyCode: "USD"
                }],
                vat: [{
                    priceValue: 0,
                    currencyCode: "USD"
                }]
            };
        },

        async fetchCartPriceOfItems(items: CartItem[]): Promise<CartPrice>{
            if (items?.length ?? 0 > 0) {
                return await store.dispatch("CALCULATE_CART_PRICE", items);
            }
            return {
                items: [],
                totalPrices: [{
                    priceValue: 0,
                    currencyCode: "USD"
                }],
                vat: [{
                    priceValue: 0,
                    currencyCode: "USD"
                }]
            };
        },

        preAddItemToCart(item: CartItem): CartItem[] {
            const items: CartItem[] = cartPlugin.getAllItems();
            const itemIndex: number = items.findIndex((d: CartItem) => d.id === item.id);
            if (itemIndex !== -1) {
                items[itemIndex].amount += item.amount;
            } else {
                items.push(item);
            }
            return items;
        },

        _listeners: {},

        async _broadcastChange(items: Array<CartItem>) {
            let cartPrice: CartPrice | null = null;
            let error: string = "";
            try {
                cartPrice = await cartPlugin.fetchCartPrice();
            } catch (e) {
                error = e.response?.data?.message ?? "";
                console.error("[Cart] Error occurred during cart price calculation: ", e);
            }
            for (const name in cartPlugin._listeners) {
                if (typeof cartPlugin._listeners[name] === "function") {
                    cartPlugin._listeners[name](items, cartPrice, error);
                }
            }
        }
    };

    Vue.prototype.$cart = cartPlugin;
}
