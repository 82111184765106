























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TDSDate, {TDSDateUnit} from "@/util/TDSDate";

interface WeekDayLegend {
    shortName: string;
    index: number;
}

interface Day {
    date: TDSDate;
    day: number;
    id: string;
    isOutOfMonth: boolean;
    isFutureDate: boolean;
}

export interface TimeRange {
    start: TDSDate;
    end: TDSDate;
}

@Component
export default class TDSDatePicker extends Vue {

    @Prop({type: Object}) initRange!: TimeRange;
    @Prop({type: Boolean, default: true}) disableFuture!: boolean;

    private time: TDSDate = new TDSDate();
    private timeZoneOffset: string = (new Date().getTimezoneOffset() * -1 / 60).toString()
    private monthYear: string = this.time.format("MMM YYYY");
    private daysPerWeek: Array<Day[]> = [];
    private selectedStartDay: Day | null = this.initRange?.start ? {
        date: this.initRange.start,
        day: Number(this.initRange.start.format("dd")),
        id: this.initRange.start.format("YYYY-MM-DD"),
        isOutOfMonth: false,
        isFutureDate: this.initRange.start.getTime() > Date.now()
    } :null;
    private selectedEndDay: Day | null = this.initRange?.end ? {
        date: this.initRange.end,
        day: Number(this.initRange.end.format("dd")),
        id: this.initRange.end.format("YYYY-MM-DD"),
        isOutOfMonth: false,
        isFutureDate: this.initRange.end.getTime() > Date.now()
    } :null;
    private startTimeValid: boolean = true;
    private endTimeValid: boolean = true;
    private periodValid: boolean = true;
    private endTimeAfterStartTime: boolean = true;
    private endTime: string = this.initRange?.end ? this.initRange.end.format("HH:mm") : "";
    private startTime: string = this.initRange?.start ? this.initRange.start.format("HH:mm") : "";

    // // // // // // // // // // HOOKS // // // // // // // // // //

    public created() {
        this.daysPerWeek = this.calculateDaysPerWeek();
    }

    // // // // // // // // // // WATCH // // // // // // // // // //

    @Watch("time")
    public onTimeChange() {
        this.monthYear = this.time.format("MMM YYYY");
        this.daysPerWeek = this.calculateDaysPerWeek();
    }

    @Watch("selectedStartDay")
    public selectedStartDayChange() {
        this.$emit("select", {
            start: this.selectedStartDay?.date,
            end: this.selectedEndDay?.date
        });
    }

    @Watch("selectedEndDay")
    public selectedEndDayChange() {
        this.$emit("select", {
            start: this.selectedStartDay?.date,
            end: this.selectedEndDay?.date
        });
    }

    // // // // // // // // // // GETTER // // // // // // // // // //

    private get daysLegend(): WeekDayLegend[] {
        return [...Array(7)].map((_: null, index: number): WeekDayLegend => {
            return {
                index,
                shortName: this.$t("general.weekDayShort." + index) + ""
            };
        });
    }

    // // // // // // // // // // METHODS // // // // // // // // // //

    private onStartTimeChange(event: InputEvent) {
        this.startTimeValid = true;
        this.periodValid = true;
        this.endTimeAfterStartTime = true;
        this.$emit("error", false);
        const startTime: string = (event.target as HTMLInputElement).value;
        if(!startTime) {
            const date = this.selectedStartDay?.date;
            date?.setHours(0);
            date?.setMinutes(0);
        } else if (/[0-9]{2}:[0-9]{2}/.test(startTime)) { // HH:mm
            const date = this.selectedStartDay?.date;
            date?.setHours(Number(startTime.split(":")[0]));
            date?.setMinutes(Number(startTime.split(":")[1]));
            if(!date || (this.disableFuture && date.getTime()>=Date.now())){
                this.periodValid = false;
                this.$emit("error", true);
            }
            else this.selectedStartDay?.date.setTime(date.getTime());
            if(this.selectedEndDay && date && date > this.selectedEndDay.date){
                this.$emit("error", true);
                this.endTimeAfterStartTime = false;
            }
        } else {
            this.startTimeValid = false;
            this.$emit("error", true);
        }
    }

    private onEndTimeChange(event: InputEvent) {
        this.endTimeValid = true;
        this.periodValid = true;
        this.endTimeAfterStartTime = true;
        this.$emit("error", false);
        const endTime: string = (event.target as HTMLInputElement).value;
        if(!endTime) {
            const date = this.selectedEndDay?.date;
            date?.setHours(0);
            date?.setMinutes(0);
        } else if (/[0-9]{2}:[0-9]{2}/.test(endTime)) { // HH:mm
            const date = this.selectedEndDay?.date;
            date?.setHours(Number(endTime.split(":")[0]));
            date?.setMinutes(Number(endTime.split(":")[1]));
            if(!date || (this.disableFuture && date.getTime()>=Date.now())) {
                this.periodValid = false;
                this.$emit("error", true);
            }
            else this.selectedEndDay?.date.setTime(date.getTime());
            if( this.selectedStartDay?.date && this.selectedEndDay?.date &&  this.selectedStartDay.date > this.selectedEndDay?.date){
                this.$emit("error", true);
                this.endTimeAfterStartTime = false;
            }
        } else {
            this.endTimeValid = false;
            this.$emit("error", true);
        }
    }

    private dayIsHighlighted(day: Day) {
        if (day.id === this.selectedStartDay?.id || day.id === this.selectedEndDay?.id) {
            return true;
        }
        return !!(this.selectedEndDay && this.selectedStartDay && day.date.getTime() < this.selectedEndDay.date.getTime() && day.date.getTime() > this.selectedStartDay.date.getTime());
    }

    private select(day: Day) {
        this.periodValid = true;
        this.endTimeAfterStartTime = true;
        if (!this.selectedStartDay) {
            this.selectedStartDay = day;
            this.startTime = day.date.format("HH:mm");
            return;
        }
        if (!this.selectedEndDay) {
            if (day.date.getTime() > this.selectedStartDay.date.getTime()) {
                this.selectedEndDay = day;
                this.selectedEndDay.date.setHours(23,59,59,0);
                const now = new TDSDate(Date.now());
                if(day.date.getDate() === now.getDate()
                    && day.date.getMonth() === now.getMonth()
                    && day.date.getFullYear() === now.getFullYear()){
                    this.selectedEndDay.date = now;
                    this.endTime = now.format("HH:mm");
                }
                else this.endTime = this.selectedEndDay.date.format("HH:mm");
            }
            if (day.date.getTime() <= this.selectedStartDay.date.getTime()) {
                this.selectedEndDay = this.selectedStartDay;
                this.selectedEndDay.date.setHours(23,59,59,999);
                const now = new TDSDate(Date.now());
                if(this.selectedStartDay.date.getDate() === now.getDate()
                    && this.selectedStartDay.date.getMonth() === now.getMonth()
                    && this.selectedStartDay.date.getFullYear() === now.getFullYear()){
                    this.selectedEndDay.date = now;
                    this.endTime = now.format("HH:mm");
                }
                else this.endTime = this.selectedStartDay.date.format("HH:mm");
                this.selectedStartDay = {
                    date: new TDSDate(day.date.getTime()),
                    day: day.day,
                    id: day.id,
                    isOutOfMonth: day.isOutOfMonth,
                    isFutureDate: day.isFutureDate
                };
                this.selectedStartDay.date.setHours(0,0,0,0);
                this.startTime = this.selectedStartDay.date.format("HH:mm");
            }
            return;
        }
        this.selectedStartDay = day;
        this.startTime = day.date.format("HH:mm");
        this.selectedEndDay = null;
        this.endTime = "";
        this.$emit("error", false);
    }

    private calculateDaysPerWeek(): Array<Day[]> {
        let loopDate = new TDSDate(this.time.getTime());
        loopDate.setHours(0);
        loopDate.setMinutes(0);
        loopDate.setDate(1);
        const dayOffset = loopDate.getDay();
        loopDate = loopDate.add(-dayOffset, TDSDateUnit.DAY);
        const days: Array<Day[]> = [];
        while (true) {
            let weekIndex = Math.floor((loopDate.getDate() + dayOffset - 1) / 7);
            let isOutOfMonth = false;
            let isFutureDate = false;
            if ((loopDate.getMonth() < this.time.getMonth() && loopDate.getFullYear() === this.time.getFullYear()) || loopDate.getFullYear() < this.time.getFullYear()) {
                weekIndex = 0;
                isOutOfMonth = true;
            } else if ((loopDate.getMonth() > this.time.getMonth() && loopDate.getFullYear() === this.time.getFullYear()) || loopDate.getFullYear() > this.time.getFullYear()) {
                if (days[days.length - 1].length === 7) return days;
                weekIndex = days.length - 1;
                isOutOfMonth = true;
            }
            if(loopDate.getTime() >= Date.now()){
                isFutureDate = true;
            }
            if (!days[weekIndex]) days[weekIndex] = [];
            days[weekIndex].push({
                isOutOfMonth,
                isFutureDate,
                date: loopDate,
                day: loopDate.getDate(),
                id: loopDate.format("YYYY-MM-DD")
            });
            loopDate = loopDate.add(1, TDSDateUnit.DAY);
        }
    }

    private add(amount: number, type: string) {
        switch (type) {
            case "YEAR":
                this.time = this.time.add(amount, TDSDateUnit.YEAR);
                break;
            case "MONTH":
                this.time = this.time.add(amount, TDSDateUnit.MONTH);
                break;
        }
    }
}
