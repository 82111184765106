











































































































































































import {Component, Vue} from "vue-property-decorator";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import {WEBHOOK_TYPES} from "@/interfaces/entities/WebhookUrl";

@Component({
    components: {TDSSpinner, TDSModal, TDSButton, Inputfield}
})
export default class Webhooks extends Vue {
    private webhooksModalOpen: boolean = false;
    private webhookType: string = "";
    private urlInput: string = "";
    private isSubmitting: boolean = false;
    private locked: boolean = false;

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get balanceAlertUrl(): string {
        return this.$store.state.balanceAlertUrl || "";
    }
    get eSimStatusUrl(): string {
        return this.$store.state.eSimStatusUrl || "";
    }
    get customerRegistrationUrl(): string {
        return this.$store.state.customerRegistrationUrl || "";
    }
    get countryChangeUrl(): string {
        return this.$store.state.countryChangeUrl || "";
    }

    async created() {
        for (const type of Object.keys(WEBHOOK_TYPES)) {
            await this.$store.dispatch("FETCH_APIWEBHOOK_URL", type );
        }
    }

    async submit() {
        if (this.locked || (this.urlInput && !(this.$refs.urlInput as Inputfield)?.submit())) return;
        this.isSubmitting = true;
        try {
            await this.$store.dispatch("SAVE_APIWEBHOOK_URL", {url: this.urlInput, type: WEBHOOK_TYPES[this.webhookType as keyof typeof WEBHOOK_TYPES]});
            await this.$store.dispatch("FETCH_APIWEBHOOK_URL", this.webhookType);
            this.locked = true;
        } catch(e) {
            console.error(`Error while saving Webhook for ${this.webhookType}:`, e);
        } finally {
            this.isSubmitting = false;
        }

    }

    enumToCamelCase(value: string) {
        let newString: string = "";
        value.split("_").forEach((word: string, index: number) => {
            if(index === 0) newString = word.toLowerCase();
            else newString += word.substr(0,1) + word.substring(1).toLowerCase();
        });
        return newString;
    }

    openModal(type: string) {
        if(type === "BALANCE_ALERT") this.urlInput = this.balanceAlertUrl;
        if(type === "ESIM_STATUS") this.urlInput = this.eSimStatusUrl;
        this.webhookType = type;
        this.webhooksModalOpen = true;
    }

    close() {
        this.webhooksModalOpen = false;
        this.urlInput = "";
    }
}
