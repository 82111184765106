import Chart, {ChartDataSets} from "chart.js";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TDSSpinner from "../../common/TDSSpinner.vue";

@Component({
    components: {TDSSpinner}
})
export default class TotalRevenueChart extends Vue {
    @Prop({type: Array}) items!: any;
    @Prop({type: String, default: "Value"}) label!: string;
    @Prop({type: Array}) labels!: any;
    @Prop({type: String, default: "rgba(251,86,81,0.0)"}) areaColor!: string;
    @Prop({type: String, default: "#FE6C13"}) lineColor!: string;
    @Prop({type: Number, default: 2}) lineWidth!: number;
    @Prop({type: Number, default: 205}) heightInPixel!: number;
    @Prop({type: Number, default: 5}) amountOfXAxisLabels!: number;
    @Prop({type: Boolean, default: true}) showLabels!: boolean;
    @Prop({type: Boolean, default: false}) isLoading!: boolean;

    private canvasId: string = "chart-" + Math.random().toString(36).substr(2, 5);
    private canvasContext: any = null;
    private canvasNode: any = null;
    private chart: any = null;

    mounted() {
        this.canvasNode = document.getElementById(this.canvasId);
        this.canvasContext = this.canvasNode.getContext("2d");
        this.draw();
    }

    beforeDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
        this.chart = null;
    }

    @Watch("items")
    itemsChanged() {
        this.draw();
    }

    private draw() {
        if (this.chart) {
            this.updateChart();
        } else {
            this.drawNewChart();
        }
    }

    private getDataset(): ChartDataSets { // trivial
        return {
            label: this.label,
            data: this.items,
            backgroundColor: this.areaColor,
            borderColor: this.lineColor,
            borderWidth: this.lineWidth,
            pointBorderColor: "rgba(0,0,0,0)",
            pointBackgroundColor: "rgba(0,0,0,0)",
            lineTension: 0.3,
            borderJoinStyle: "round"
        };
    }

    private updateChart() {
        this.chart.data.labels = this.labels;
        this.chart.data.datasets = [this.getDataset()];
        this.chart.update();
    }

    private drawNewChart() {
        this.chart = new Chart(this.canvasContext, {
            type: "line",
            data: {
                labels: this.labels,
                datasets: [this.getDataset()]
            },
            options: {
                tooltips: {
                    enabled: false,

                    custom: (tooltipModel: any) => {
                        // Tooltip Element
                        let tooltipEl = document.getElementById("chartjs-tooltip");

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement("div");
                            tooltipEl.id = "chartjs-tooltip";
                            tooltipEl.innerHTML = "<div class=\"tooltip infobox\" x-placement=\"top\"></div>";
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.display = "none";
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove("above", "below", "no-transform");
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add("no-transform");
                        }

                        function getBody(bodyItem: any) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title[0]; //this.labels.find((l: string) => l === tooltipModel.title[0]).split("/")[1];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = "<div class=\"tooltip-arrow\"></div><div class=\"tooltip-inner\">";
                            if (titleLines) innerHtml += "<label>" + titleLines + "</label>";
                            bodyLines.forEach(function (body: any, i: any) {
                                innerHtml += parseFloat(body[0].split(": ")[1]).toFixed( 2 );
                            });
                            innerHtml += "<label>" + this.$t("yourStore.dashboard.charts.total.label") +"</label></div>";

                            const tableRoot: any = tooltipEl.querySelector(".tooltip");
                            tableRoot.style.opacity = 1;
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        // @ts-ignore
                        const position = this.$refs.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.display = "block";
                        // @ts-ignore
                        tooltipEl.style.zIndex = 3;
                        tooltipEl.style.position = "absolute";
                        // tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
                        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - (tooltipEl.offsetWidth/2) + "px";
                        // tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px";
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - (tooltipModel.height*1.6) + "px";
                        tooltipEl.style.pointerEvents = "none";
                        // tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
                    }
                },
                animation: {
                    duration: 0,
                    easing: "easeOutExpo"
                },
                layout: {
                    padding: {
                        left: 2,
                        right: 0,
                        top: 5,
                        bottom: 5
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        display: true,
                        gridLines: {
                            display: true,
                            drawBorder: false,
                            zeroLineWidth: 0.5,
                            drawTicks: false
                        },
                        ticks: {
                            display: false,
                            // maxTicksLimit: 6,
                            // labelOffset: 10,
                            fontSize: 10,
                            fontFamily: "Mark W04",
                            maxRotation: 0,
                            minRotation: 0,
                            mirror: false,
                            lineHeight: 0,
                            // padding: 5,
                            beginAtZero: true,

                            callback: function (value: any, index: number, values: any) {
                                return Number.isInteger((parseFloat(value))) ? value : "";
                            }
                        }
                    }],
                    xAxes: [{
                        display: this.showLabels,
                        gridLines: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            maxTicksLimit: 6,//this.amountOfXAxisLabels,
                            // stepSize: 3,
                            // labelOffset: 10,
                            fontSize: 10,
                            fontFamily: "Mark W04",
                            maxRotation: 0,
                            minRotation: 0,
                            mirror: false,
                            lineHeight: 0,
                            padding: 0

                            // callback: function (value: any, index: number, values: any) {
                            //     return value.split("/")[0];
                            // }
                        }
                    }]
                }
            }
        });
    }
}
