









































































































































































































import { Component, Vue } from "vue-property-decorator";
import LandingPageLayout from "../layouts/LandingPageLayout.vue";
import ImgText from "../partials/ImgText.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import CardContent from "../partials/CardContent.vue";
import VidText from "@/theme/dent/components/partials/VidText.vue";
import CardContentGrey from "@/theme/dent/components/partials/CardContentGrey.vue";

@Component({
    components: {
        CardContentGrey,
        VidText,
        LandingPageLayout,
        ImgText,
        TDSButton,
        CardContent
    }
})
export default class LandingPage extends Vue {
    authInProgress: boolean = false;
    registrationInProgress: boolean = false;
    private privacyPolicyExists: boolean = false;
    private observer: IntersectionObserver | null = null;

    get isMobile() {
        return this.$store.state.isMobile;
    }

    get authenticated(): boolean {
        return !!this.$store.state.auth;
    }

    beforeDestroy() {
        if (this.observer) this.observer.disconnect();
    }

    async registerUser() {
        this.registrationInProgress = true;
        try {
            await this.$authenticator.registerUser();
        } catch (e) {
            console.error("[LandingPage] Error on registration: ", e);
        } finally {
            setTimeout(() => {
                this.registrationInProgress = false;
            }, 500);
        }
    }

    async loginUser() {
        this.authInProgress = true;
        try {
            await this.$authenticator.signIn();
        } catch (e) {
            console.error("[LandingPage] Error on login: ", e);
        } finally {
            setTimeout(() => {
                this.authInProgress = false;
            }, 500);
        }
    }

    created() {
        this.getPrivacyPolicy();
    }

    private async getPrivacyPolicy() {
        try {
            (await this.$store.dispatch("FETCH_PRIVACY_POLICY")) !== null;
            this.privacyPolicyExists = true;
        } catch (e) {
            this.privacyPolicyExists = false;
        }
    }
}
