







































import {Component, Prop, Vue} from "vue-property-decorator";
import TDSButton from "@/components/common/TDSButton.vue";
import {tools} from "@/util/tools";
import TDSError from "@/components/common/TDSError.vue";
import TDSDate from "@/util/TDSDate";

@Component({
    components: {TDSButton, TDSError}
})
export default class CardContent extends Vue {
    private isReady: boolean = false;
    private csvContent: string = "";
    private error: string = "";

    @Prop({type: String, required: true})
    readonly storeAction!: string;

    @Prop({type: String, required: true})
    readonly start!: string;

    @Prop({type: String, required: true})
    readonly end!: string;

    @Prop({type: String, default: "yourStore.history.exportError"})
    readonly errorMessageKey!: string;

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        this.downloadCsv();
    }

    private async downloadCsv() {
        this.isReady = false;
        try {
            this.csvContent = await this.$store.dispatch(this.storeAction, {
                start: this.start,
                end: this.end});
        } catch (e) {
            console.error("[CSVExport] Error on CSV export: ", e);
            this.error = this.$t(this.errorMessageKey).toString();
        }
        this.isReady = true;
    }

    private saveCsv() {
        if (!this.isReady) return;
        tools.saveFile(
            `gigastore-export-${Math.floor(Date.now() / 1000)}.csv`,
            `data:text/csv;charset=utf-8,${this.csvContent}`
        );
    }

}
