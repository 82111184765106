
import countries from "i18n-iso-countries";

import countriesEn from "i18n-iso-countries/langs/en.json";
import countriesDe from "i18n-iso-countries/langs/de.json";

countries.registerLocale(countriesEn);
countries.registerLocale(countriesDe);

export default countries;

