import axios from "@/util/axios";
import {VuexActionContext} from "@/store/index";
import {Product} from "@/interfaces/entities/Product";
import {GetESIMDataResponse} from "@/interfaces/httpResponses/GetESIMDataResponse";

export const eSimActions = {
    async FETCH_ESIM_DATA(context: VuexActionContext<GetESIMDataResponse>, token: string): Promise<GetESIMDataResponse> {
        const response = await axios.get("/esim/profile?token=" + token);
        console.log("[ESIMStore] Got eSIM: ", response.data);
        return response;
    }
};
