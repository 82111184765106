

















































import {Component, Prop, Vue} from "vue-property-decorator";
import TDSModal from "../../../../../components/partials/TDSModal/TDSModal.vue";
import Inputfield from "../../../../../components/partials/Inputfield.vue";
import TDSButton from "../../../../../components/common/TDSButton.vue";
import {Price} from "@/interfaces/entities/Price";

@Component({
    components: {TDSModal, Inputfield, TDSButton}
})
export default class CustomPaymentOption extends Vue {

    @Prop({type: Array, default: () => []})
    private prices!: Array<Price>;

    @Prop({type: Boolean, default: false})
    private inProgress!: boolean;

    submit() {
        this.$emit("next");
    }

}
