


























import {Component, Vue} from "vue-property-decorator";
import StoreLayout from "../layouts/StoreLayout.vue";
import PackageCard from "@/components/partials/PackageCard.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";

@Component({
    components: {TDSSpinner, TDSButton, PackageCard, StoreLayout}
})
export default class POSStore extends Vue {}
