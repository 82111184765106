























import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CardContent extends Vue {
  @Prop({required: true}) imgsrc!: string;
  @Prop({required: true}) title!: string;
  @Prop({required: true}) subtitle!: string;
  @Prop({default: true}) offerActive!: boolean;
  @Prop() internalLink!: string;
  @Prop() linkText!: string;
}
