
















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import TableList from "@/components/partials/TableList.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import TotalRevenueChart from "@/components/partials/Charts/TotalRevenueChart.vue";
import MultiLineChart from "@/components/partials/Charts/MultiLineChart.vue";
import TDSDatePicker, { TimeRange } from "@/components/common/TDSDatePicker.vue";
import TDSDate, { TDSDateUnit } from "@/util/TDSDate";
import { ChartCandle, ChartCandlesPerItem } from "@/interfaces/entities/ChartCandleSet";

export interface RevenueItemType {
    id: string;
    label?: string;
    data: number[];
    backgroundColor?: string;
    borderColor: string;
    borderWidth?: number;
    percentage?: number;
}

@Component({
    components: {
        TDSDatePicker,
        MultiLineChart,
        TotalRevenueChart,
        TableList,
        TDSModal,
        TDSButton,
        Inputfield
    }
})
export default class Inventory extends Vue {
    private totalRevenueItems: number[] = [];
    private totalRevenueLabels: string[] = [];
    private itemRevenueLabels: string[] = [];
    private colors: Array<string> = ["#83b34e", "#20a4f3", "#e67540", "#57607a", "#909090"];
    private totalRevenuePercentage: number = 0;
    private showNoSales: boolean = false;
    private revenueLabels: string[] = [];
    private revenueItems: RevenueItemType[] = [];
    private activeItems: string[] = this.revenueItems.map((item: RevenueItemType) => item.id);
    private showTimeRangeModal: boolean = false;
    private selectedTimeRange?: TimeRange = undefined;
    private datePickerHasError: boolean = false;
    private shownTimeRange: string = "";
    private selectedPeriod: string = "past30d";
    private chartIsLoading: boolean = false;
    private historyLoaded: boolean = false;
    private timeZoneOffsetInMinutes: number = new Date().getTimezoneOffset();
    private maxValue: number = 10;
    private chartTypeIsCandle: boolean = false;

    get activeRevenueItems() {
        return this.revenueItems.filter((item: RevenueItemType) => this.activeItems.includes(item.id));
    }

    get totalRevenue(): number {
        return this.totalRevenueItems.reduce((prev: number, curr: number) => prev + curr, 0);
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    @Watch("selectedPeriod")
    selectedPeriodChanged(val: string) {
        if (val) {
            this.setTimeRange();
            this.fetchData();
        }
    }

    @Watch("$route.params")
    paramsChanged() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("yourStore.dashboard.pageTitle");
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("yourStore.dashboard.pageTitle");
        this.setTimeRange();
        this.$store
            .dispatch("FETCH_SOLD_ITEMS")
            .then(() => {
                this.historyLoaded = true;
            })
            .catch(() => {
                this.historyLoaded = true;
            });
        this.fetchFirstData();
    }

    async fetchFirstData() {
        await this.fetchData();
        this.activeItems = this.revenueItems.map((item: RevenueItemType) => item.id);
    }

    private async fetchData() {
        this.chartIsLoading = true;
        try {
            this.maxValue = 10;
            const granularity = this.getGranularityForTimeRange();
            const start = this.selectedTimeRange?.start?.add(this.timeZoneOffsetInMinutes / 60, TDSDateUnit.HOUR)?.format("YYYY-MM-DDTHH:mm:ss.000Z");
            const end = this.selectedTimeRange?.end
                ? this.selectedTimeRange?.end?.add(this.timeZoneOffsetInMinutes / 60, TDSDateUnit.HOUR)?.format("YYYY-MM-DDTHH:mm:ss.000Z")
                : new TDSDate(Date.now()).add(this.timeZoneOffsetInMinutes / 60, TDSDateUnit.HOUR).format("YYYY-MM-DDTHH:mm:ss.000Z");
            const response = await this.$store.dispatch("FETCH_REVENUE_CHART_CANDLES", {
                granularity,
                start,
                end
            });
            this.showNoSales = response.showIntroMessage;
            const chartCandles = response.totalRevenue.dataSet;
            this.totalRevenueItems = chartCandles.map((chartCandle: ChartCandle) => {
                return chartCandle.value;
            });
            this.totalRevenueLabels = chartCandles.map((chartCandle: ChartCandle): string => {
                return new TDSDate(Date.parse(chartCandle.timestamp)).format(this.getTimeFormat());
            });
            this.totalRevenuePercentage = response.totalRevenue.changeRate * 100;
            this.revenueItems = response.revenuePerItem.map(
                (chartCandlesOfItem: ChartCandlesPerItem, index: number): RevenueItemType => {
                    const revenueItem: RevenueItemType = {
                        borderColor: this.colors[index % this.colors.length],
                        data: chartCandlesOfItem.revenue.dataSet.map((chartCandle: ChartCandle) => {
                            return chartCandle.value;
                        }),
                        id: chartCandlesOfItem.inventoryItemId,
                        label: chartCandlesOfItem.inventoryItemName,
                        // backgroundColor: this.colors[index % this.colors.length],
                        percentage: chartCandlesOfItem.revenue.changeRate * 100
                    };
                    const max = Math.max(...revenueItem.data);
                    if (max > this.maxValue) {
                        this.maxValue = max;
                    }
                    return revenueItem;
                }
            );
            this.itemRevenueLabels = response.revenuePerItem[0].revenue.dataSet.map((chartCandle: ChartCandle): string => {
                try {
                    return new TDSDate(Date.parse(chartCandle.timestamp)).format(this.getTimeFormat());
                } catch (e) {
                    return "";
                }
            });
        } catch (e) {
            console.error("[StoreDashboard] Chart load failed: ", e);
        }
        this.chartIsLoading = false;
    }

    getGranularityForTimeRange(): string {
        switch (this.selectedPeriod) {
            case "past24h":
                return "_1H";
            case "past7d":
                return "_1D";
            case "past30d":
                return "_1D";
            case "pastYear":
                return "_30D";
            case "allTime":
                return "_30D";
            default:
                return "_1H";
        }
    }

    getTimeFormat(): string {
        switch (this.getGranularityForTimeRange()) {
            case "_1H":
                return "DD/MM/YYYY HH:mm";
            case "_1D":
                return "DD/MM/YYYY";
            case "_30D":
                return "MMM YYYY";
            default:
                return "DD/MM/YYYY";
        }
    }

    private toggleTimeRangeModal() {
        this.showTimeRangeModal = !this.showTimeRangeModal;
    }

    private setTimeRange() {
        switch (this.selectedPeriod) {
            case "past24h":
                this.selectedTimeRange = {
                    start: new TDSDate(Date.now()).add(-1, TDSDateUnit.DAY),
                    end: new TDSDate(Date.now())
                };
                break;
            case "past7d":
                this.selectedTimeRange = {
                    start: new TDSDate(Date.now()).add(-7, TDSDateUnit.DAY),
                    end: new TDSDate(Date.now())
                };
                break;
            case "past30d":
                this.selectedTimeRange = {
                    start: new TDSDate(Date.now()).add(-30, TDSDateUnit.DAY),
                    end: new TDSDate(Date.now())
                };
                break;
            case "pastYear":
                this.selectedTimeRange = {
                    start: new TDSDate(Date.now()).add(-1, TDSDateUnit.YEAR),
                    end: new TDSDate(Date.now())
                };
                break;
            case "allTime":
                this.selectedTimeRange = {
                    start: new TDSDate(Date.now()).add(-3, TDSDateUnit.YEAR),
                    end: new TDSDate(Date.now())
                };
                break;
            default:
                this.selectedTimeRange = {
                    start: new TDSDate(Date.now()).add(-3, TDSDateUnit.YEAR),
                    end: new TDSDate(Date.now())
                };
        }

        this.setShownTimeRange();
    }

    private timeRangeSelectedInModal(eventData: TimeRange) {
        this.selectedTimeRange = eventData;
        this.selectedPeriod = "";
        console.log("[Dashboard] User selected time range: ", this.selectedTimeRange);
    }

    private filterByTimeRange() {
        if (this.datePickerHasError) return;
        this.fetchData();
        this.setShownTimeRange();
        this.showTimeRangeModal = false;
    }

    setShownTimeRange() {
        this.shownTimeRange = "";
        if (!this.selectedTimeRange) this.shownTimeRange = "Select Timerange";
        if (this.selectedTimeRange?.start) this.shownTimeRange += this.selectedTimeRange.start.format("MMM DD, YYYY");
        if (this.selectedTimeRange?.start && this.selectedTimeRange?.end) this.shownTimeRange += " - ";
        if (this.selectedTimeRange?.end) this.shownTimeRange += this.selectedTimeRange.end.format("MMM DD, YYYY");
    }

    private toggleActivity(id: string) {
        if (this.activeItems.includes(id))
            this.activeItems.splice(
                this.activeItems.findIndex((item: string) => item === id),
                1
            );
        else this.activeItems.push(id);
    }
}
