import Vue from "vue";
import Vuex from "vuex";
import axios from "../util/axios";
import {VersionInfo, VersionResponse} from "@/interfaces/httpResponses/GetVersionResponse";
import {userActions, userMutations, UserState, userState} from "./userStore";
import {customerActions, customerMutations, CustomerState, customerState} from "./customerStore";
import {soldItemsActions, soldItemsMutations, SoldItemsState, soldItemsState} from "@/store/soldItemsStore";
import {SoldItem} from "@/interfaces/entities/SoldItem";
import {APIActions, APIMutations, APIState, apiState} from "@/store/apiStore";
import {productsActions, productsMutations, ProductsState, productsState} from "@/store/productsStore";
import {PaymentsState, paymentsState, paymentsActions, paymentsMutations} from "@/store/paymentsStore";
import {inventoryActions} from "@/store/inventoryStore";
import {SdkActions, SDKMutations, sdkState, SDKState} from "@/store/sdkStore";
import {eSimActions} from "@/store/eSIMStore";
import {legalContentActions} from "@/store/legalContentStore";
import {countriesState, CountriesState} from "@/store/countriesStore";

Vue.use(Vuex);

export interface DefaultState extends UserState, CustomerState, SoldItemsState, APIState, SDKState, ProductsState, PaymentsState, CountriesState {
    auth: string;
    apiVersion: string;
    headerHeight: string;
    isMobile: boolean;
    previousView: string;
}

export interface VuexActionContext<T> {
    commit: (mutationId: string, data: T) => void;
}

export default new Vuex.Store({
    state: {
        auth: "",
        apiVersion: "",
        headerHeight: "213px",
        isMobile: false,
        previousView: "",
        ...userState,
        ...customerState,
        ...soldItemsState,
        ...apiState,
        ...sdkState,
        ...productsState,
        ...paymentsState,
        ...countriesState
    },
    mutations: {
        SET_AUTH(state: DefaultState, auth: string) {
            state.auth = auth;
        },
        SET_API_VERSION(state: DefaultState, versionInfo: VersionInfo) {
            state.apiVersion = `${versionInfo.version} (${versionInfo.commitHash})`;
        },
        SET_HEADER_HEIGHT(state: DefaultState, headerHeight: string) {
            state.headerHeight = headerHeight;
        },
        SET_IS_MOBILE(state: DefaultState, isMobile: boolean) {
            state.isMobile = isMobile;
        },
        SET_PREVIOUS_VIEW(state: DefaultState, previousView: string) {
            state.previousView = previousView;
        },
        ...userMutations,
        ...soldItemsMutations,
        ...APIMutations,
        ...SDKMutations,
        ...productsMutations,
        ...paymentsMutations
    },
    actions: {
        async FETCH_API_VERSION({commit}: VuexActionContext<VersionInfo>) {
            const response: VersionResponse = await axios.get("/version");
            console.log("[store] Got server version: ", response);
            commit("SET_API_VERSION", response.data);
        },
        ...userActions,
        ...customerActions,
        ...soldItemsActions,
        ...APIActions,
        ...productsActions,
        ...paymentsActions,
        ...inventoryActions,
        ...eSimActions,
        ...SdkActions,
        ...legalContentActions
    },
    getters: {}
});
