










































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import TableList from "@/components/partials/TableList.vue";
import SoldItemCardContent from "@/components/partials/YourStore/SoldItemCardContent.vue";
import { ActivatedItem } from "@/interfaces/entities/ActivatedItem";
import AddCustomerEmailModal from "@/components/partials/AddCustomerEmailModal.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import ESimDetailsModal from "@/components/partials/ESimDetailsModal.vue";

@Component({
    components: {
        ESimDetailsModal,
        TDSModal,
        SoldItemCardContent,
        TableList,
        DefaultLayout,
        AddCustomerEmailModal,
        TDSButton
    }
})
export default class HistoryDetails extends Vue {
    private isLoading: boolean = false;
    private soldItem: ActivatedItem | null = null;
    private tableColumnsHistory: object = {
        date: { label: this.$t("tableHeader.date") as string, width: "140px" },
        name: { label: this.$t("tableHeader.dataPackage") as string },
        volume: { label: this.$t("tableHeader.volume") as string, width: "100px", align: "right" },
        validity: { label: this.$t("tableHeader.validity") as string, width: "100px", align: "right" },
        purchasePrice: { label: this.$t("tableHeader.purchasePrice") as string, width: "138px", align: "right" },
        retailPrice: { label: this.$t("tableHeader.retailPrice") as string, width: "138px", align: "right" }
    };
    private tableColumnsHistoryAddition: object = {
        channel: { label: this.$t("tableHeader.channel") as string, width: "150px" },
        activationId: { label: this.$t("tableHeader.activationId") as string, width: "160px"},
        metatag: { label: this.$t("tableHeader.metatag") as string },
        status: { label: this.$t("tableHeader.status") as string, width: "100px", align: "right" },
        actions: { label: this.$t("tableHeader.actions") as string, align: "right" }
    };
    private tableColumnsCustomer: object = {
        customer: { label: this.$t("tableHeader.customerProfile") as string, width: "275px" },
        email: { label: this.$t("tableHeader.email") as string },
        balance: { label: this.$t("tableHeader.balance") as string, width: "150px" },
        profiles: { label: this.$t("tableHeader.attachedProfiles") as string, width: "200px" }
    };
    private tableColumnsDetails: object = {
        ICCID: { label: "ICCID" },
        status: { label: this.$t("tableHeader.status") as string },
        installationLink: { label: this.$t("tableHeader.installationLink") as string, width: "290px" }
    };
    private copySuccess: boolean = false;
    private showEmailModal: boolean = false;
    private showDetailsModal: boolean = false;
    private eSimUid: string = "";
    private showMetatagModal: boolean = false;
    private metatagIsValidXml: boolean = true;
    private showRefundModal: boolean = false;
    private isRefunding: boolean = false;

    created() {
        this.fetchSoldItem();
        if (this.$route.query.sortDirHistory && this.$route.query.sortKeyHistory) {
            this.$store.commit("SET_PREVIOUS_VIEW", "/your-store/history?sortDir=" + this.$route.query.sortDirHistory + "&sortKey=" + this.$route.query.sortKeyHistory);
        } else {
            this.$store.commit("SET_PREVIOUS_VIEW", "/your-store/history");
        }
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    private reload() {
        this.showEmailModal = false;
        this.fetchSoldItem();
    }

    private async fetchSoldItem() {
        this.isLoading = true;
        try {
            this.soldItem = await this.$store.dispatch("GET_SOLD_ITEM_BY_ID", this.$route.params.id);
            if (this.soldItem?.relatedEsims?.length === 0) {
                this.soldItem.relatedEsims.push({
                    activatedAt: null,
                    activationCode: "---",
                    iccid: "---",
                    imsi: "---",
                    installationUrl: "---",
                    lastSeen: null,
                    state: "---",
                    uid: "---",
                    active: false
                });
            }
        } catch (e) {
            console.error("[Your Store Inventory] Failed fetching sold item");
        } finally {
            this.isLoading = false;
            this.metatagIsValidXml = this.isValidXml(this.soldItem ? this.soldItem.activatedItem.metatag : "");
        }
    }

    get metatagIsLong(): boolean {
        if (!this.soldItem) return false;
        if (this.isMobile) return this.soldItem.activatedItem.metatag.length >= 30;
        else return this.soldItem.activatedItem.metatag.length >= 50;
    }

    private isValidXml(metatag: string): boolean {
        const parser = new DOMParser();
        const parsererrorNS = parser.parseFromString("INVALID", "text/xml").getElementsByTagName("parsererror")[0].namespaceURI;
        const document = parser.parseFromString(metatag ? metatag : "", "text/xml");
        return document.getElementsByTagNameNS(parsererrorNS ? parsererrorNS : "http://www.w3.org/1999/xhtml", "parsererror").length <= 0;
    }

    private async copyToClipBoard(text: string) {
        if (!text) return;
        await navigator.clipboard.writeText(text);
        this.$toastr.success(this.$t("components.inputfield.copied"), true, true);
    }

    openAddEmailModal() {
        this.showEmailModal = true;
    }

    openMetatagModal() {
        this.showMetatagModal = true;
        this.$nextTick(() => {
            this.calcTextareaHeight();
        });
    }

    openDetailsModal(iccid: string) {
        this.eSimUid = iccid;
        this.showDetailsModal = true;
    }

    private calcTextareaHeight() {
        const textarea = this.$refs.metatag as HTMLTextAreaElement;
        if (!textarea) return;
        const lines = textarea.value.split(/\n/g) || [];
        const maxChars = this.isMobile ? 33 : 100;
        let numberOfLines = 0;
        lines.forEach((line: string) => {
            numberOfLines += Math.ceil(line.length / maxChars);
        });
        textarea.style.height = (this.isMobile ? numberOfLines * 16 + 70 : numberOfLines * 14 + 46) + "px";
    }

    private async performRefund() {
        try {
            this.isRefunding = true;
            const result = await this.$store.dispatch("REFUND_SOLD_ITEM", this.soldItem?.activatedItem.uid);
            console.log("Result:", result);
            this.showRefundModal = false;
            this.$toastr.success(this.$t("yourStore.history.details.refundSuccess"));
        } catch (error) {
            console.error("[Refund] Failed to refund item:", error);
            this.$toastr.error(this.$t("yourStore.history.details.refundError"));
        } finally {
            this.isRefunding = false;
            this.reload();
        }
    }
}
