import _Vue from "vue";
import {Product} from "@/interfaces/entities/Product";

interface Callback {
    (items: Product[] | null): void;
}

interface InventoryPlugin {

    getAllItems(): Product[];

    onChange(name: string, callback: Callback): void;

    removeListener(name: string): void;

    addItem(item: Product): void;

    emptyCart(): void;

    removeItem(id: string): void;

    // preAddItemToCart(item: Product): Product[];

    _broadcastChange(items: Array<Product>): void;

    _listeners: { [name: string]: Callback };
}

export function Inventory(Vue: typeof _Vue): void {

    const inventoryPlugin: InventoryPlugin = {

        onChange(name: string, callback: Callback) {
            inventoryPlugin._listeners[name] = callback;
        },

        removeListener(name: string) {
            delete inventoryPlugin._listeners[name];
        },

        getAllItems(): Product[] {
            try {
                const itemsString: string = localStorage.inventory;
                const items: Product[] = JSON.parse(itemsString);
                if (Array.isArray(items)) return items;
                else return [];
            } catch (e) {
                return [];
            }

        },

        addItem(item: Product): void {
            const items: Product[] = inventoryPlugin.getAllItems();
            const itemIndex: number = items.findIndex((d: Product) => d.id === item.id);
            if (itemIndex === -1)  items.push(item);
            localStorage.inventory = JSON.stringify(items);
            inventoryPlugin._broadcastChange(items);
        },

        emptyCart(): void {
            localStorage.removeItem("inventory");
            inventoryPlugin._broadcastChange([]);
        },

        removeItem(id: string): void {
            const items: Product[] = inventoryPlugin.getAllItems();
            items.splice(items.findIndex((d: Product) => d.id === id), 1);
            localStorage.inventory = JSON.stringify(items);
            inventoryPlugin._broadcastChange(items);
        },

        // preAddItemToCart(item: Product): Product[] {
        //     const items: Product[] = inventoryPlugin.getAllItems();
        //     const itemIndex: number = items.findIndex((d: Product) => d.id === item.id);
        //     if (itemIndex === -1) items.push(item);
        //     return items;
        // },

        _listeners: {},

        async _broadcastChange(items: Array<Product>) {
            // let cartPrice: CartPrice | null = null;
            // let error: string = "";
            // try {
            //     cartPrice = await inventoryPlugin.fetchCartPrice();
            // } catch (e) {
            //     error = e.response?.data?.message ?? "";
            //     console.error("[Cart] Error occurred during cart price calculation: ", e);
            // }
            for (const name in inventoryPlugin._listeners) {
                if (typeof inventoryPlugin._listeners[name] === "function") {
                    inventoryPlugin._listeners[name](items);
                }
            }
        }
    };

    Vue.prototype.$inventory = inventoryPlugin;
}
