



























import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class InputField extends Vue {

    @Prop({type: Boolean, default: true}) isValid!: boolean;
    @Prop({type: Boolean, default: false}) isReadOnly!: boolean;
    @Prop({type: Boolean, default: false}) hasAutofocus!: boolean;
    @Prop({required: true}) value!: string | number;
    @Prop({type: String, default: "text", validator: InputField.typePropValidator}) type!: string;
    @Prop({type: RegExp}) validationRegex?: RegExp;
    @Prop({type: String, required: true}) placeholder!: string;
    @Prop({type: String, default: "off"}) autocomplete!: string;

    private hasFocus: boolean = false;

    mounted() {
        if (this.hasAutofocus && this.$refs.input) {
            (this.$refs.input as HTMLInputElement).focus();
        }
    }

    onInput(event: InputEvent) {
        if (this.isReadOnly) return;
        const value = (event.target as HTMLInputElement).value;
        const isValid = this.validationRegex === undefined || this.validationRegex.test(value);
        this.$emit("update:value", value);
        this.$emit("update:is-valid", isValid);
        this.$nextTick(() => {
            if (isValid !== this.isValid) {
                console.error("[TDSInputField] The 'is-valid' property should be used with '.sync' like :is-valid.sync=...");
            }
        });
    }

    onEnterPress() {
        this.$emit("enterPressed");
        (this.$refs.input as HTMLInputElement).blur();
    }

    static typePropValidator(value: string): boolean {
        return ["text", "number", "password", "email"].includes(value);
    }
}
