














































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DefaultFooter from "@/components/partials/DefaultFooter.vue";
import MobileMenuButton from "@/components/partials/Navigation/MobileMenuButton.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import { JoinerBonusInfo } from "@/interfaces/entities/JoinerBonusInfo";
import { GetCreditInfosResponse } from "@/interfaces/httpResponses/GetCreditInfosResponse";

interface HeaderInfoItem {
    title: string;
    subtitle: string;
    linkText: string;
    internalLink?: string;
    externalLink?: string;
    hideVar: string;
}

@Component({
    components: { TDSButton, TDSSpinner, MobileMenuButton, DefaultFooter }
})
export default class DefaultNavigationBar extends Vue {
    private mobileNavOpen: boolean = false;
    private isLoading: boolean = false;
    private headerInfos: Array<HeaderInfoItem> = [];
    public creditBalance: number = 0;

    get authenticated(): boolean {
        return !!this.$store.state.auth;
    }

    get userBalance(): number {
        return this.$store.state.user?.balance;
    }

    get isMobile(): boolean {
        return this.$store.state.isMobile;
    }

    get previousView(): boolean {
        return this.$store.state.previousView;
    }

    created() {
        if (this.isMobile) this.init();
        this.$store.dispatch("FETCH_CREDIT_INFOS").then((data: GetCreditInfosResponse)=>{
            this.creditBalance = data.balance.value;
        });
    }

    @Watch("mobileNavOpen")
    mobileNavChanged(newVal: boolean) {
        if (newVal) document.body.style.position = "fixed";
        else document.body.style.position = "relative";
    }

    @Watch("isMobile")
    mobileChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
        else this.init();
    }

    @Watch("authenticated")
    authChanged(newVal: boolean) {
        if (!newVal) this.mobileNavOpen = false;
        else this.init();
    }

    @Watch("headerInfos")
    headerInfosChanged(newVal: Array<HeaderInfoItem>) {
        if (newVal.length > 0) (this.$refs.infoHeader as HTMLElement).style.maxHeight = "200px";
        else setTimeout(() => ((this.$refs.infoHeader as HTMLElement).style.maxHeight = "0"), 500);
    }

    private init() {
        this.$store.dispatch("FETCH_SOLD_ITEMS").then(() => {
            if (this.$store.state.soldItems.length === 0) {
                const headerInfos: Array<HeaderInfoItem> = [];
                if (!this.$storage.get("hideChannelHint")) {
                    headerInfos.push({
                        title: String(this.$t("yourStore.salesChannels.title")),
                        subtitle: String(this.$t("yourStore.salesChannels.subtitle")),
                        linkText: String(this.$t("yourStore.salesChannels.linkText")),
                        internalLink: "/your-store/channels",
                        hideVar: "hideChannelHint"
                    });
                }
                this.$store.dispatch("FETCH_JOINER_BONUS_INFO").then((joinerBonusInfo: JoinerBonusInfo) => {
                    if (joinerBonusInfo.showToUser && !this.$storage.get("hideJoinerBonus")) {
                        headerInfos.push({
                            title: String(this.$t("yourStore.welcomeBonus.title")),
                            subtitle: String(this.$t("yourStore.welcomeBonus.subtitle", [joinerBonusInfo.amount + "x " + joinerBonusInfo.name])),
                            linkText: String(this.$t("yourStore.welcomeBonus.linkText")),
                            internalLink: "/your-store/inventory",
                            hideVar: "hideJoinerBonus"
                        });
                    }
                    this.headerInfos = headerInfos;
                });
            }
        });
    }

    private closeHeaderInfo(hideVar: string) {
        this.headerInfos.splice(this.headerInfos.length - 1, 1);
        this.$storage.set(hideVar, true);
    }

    private goTo(link: string) {
        this.$router.push(link);
        if (this.isMobile) this.mobileNavOpen = false;
    }

    public async login(): Promise<void> {
        this.isLoading = true;
        try {
            await this.$authenticator.signIn();
        } catch (e) {
            this.$toastr.error(this.$t("navigation.errors.login"), e);
            this.isLoading = false;
        }
    }

    public async logout(): Promise<void> {
        this.isLoading = true;
        try {
            await this.$authenticator.signOut();
        } catch (e) {
            this.$toastr.error(this.$t("navigation.errors.logout"), e);
            this.isLoading = false;
        }
    }
}
