




















import {Component, Vue} from "vue-property-decorator";

@Component
export default class DefaultFooter extends Vue {
    created() {
        this.$store.dispatch("FETCH_API_VERSION");
    }
}
