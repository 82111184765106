import axios from "@/util/axios";
import {VuexActionContext} from "@/store/index";
import {SdkKey} from "@/interfaces/entities/SdkKey";
import {DeviceVerificationUrlUpdate} from "@/interfaces/entities/DeviceVerificationUrl";

export interface SDKState {
    sdkChannels: SdkKey[];
}

export const sdkState: SDKState = {
    sdkChannels: []
};

export const SDKMutations = {
    ADD_NEW_SDK_CHANNEL(state: SDKState, sdkKey: SdkKey) {
        if (!state.sdkChannels) return;
        if(state.sdkChannels.find((item: SdkKey) => item.key === sdkKey.key)) return;
        state.sdkChannels.push(sdkKey);
    },
    ADD_WEBHOOK_URL_TO_CHANNEL(state: SDKState, deviceVerificationUrl: DeviceVerificationUrlUpdate){
        if (!state.sdkChannels) return;
        const channelIndex = state.sdkChannels.findIndex((item: SdkKey) => item.key === deviceVerificationUrl.key);
        if(channelIndex === -1) return;
        else state.sdkChannels[channelIndex].webhookUrl = deviceVerificationUrl.url;
    }
};

export const SdkActions = {
    async FETCH_SDK_KEYS({commit}: VuexActionContext<SdkKey>) {
        const response = await axios.get("/users/current/sdk-key");
        console.log("[SDKStore] Got SDK keys!", response.data);
        commit("ADD_NEW_SDK_CHANNEL", response.data[0]);
    },
    async SAVE_WEBHOOK_URL(context: VuexActionContext<void>, data: DeviceVerificationUrlUpdate) {
        const config = {
            headers: {
                sdkKey: data.key
            }
        };
        const response =  await axios.post("/users/current/webhook-url/device-verification",{url: data.url}, config);
        console.log("[SDKStore] Saved webhook url for device verification!", response.status);
        return response;
    },
    async FETCH_WEBHOOK_URL({commit}: VuexActionContext<DeviceVerificationUrlUpdate>, sdkKey: string) {
        const config = {
            headers: {
                sdkKey: sdkKey
            }
        };
        const response = await axios.get("/users/current/webhook-url/device-verification", config);
        console.log("[SDKStore] Got Webhook url for sdk key!", response.data);
        commit("ADD_WEBHOOK_URL_TO_CHANNEL", {url: response.data.url, key: sdkKey});
    }
};
