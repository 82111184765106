import _Vue from "vue";

export function ImagePath(Vue: typeof _Vue): void {
    function imagePath(imageName: string) {
        if (!imageName) return;
        if (process.env.VUE_APP_THEME) {
            return require("../theme/" + process.env.VUE_APP_THEME + "/images/" + imageName);
        }
        return require("../assets/" + imageName);
    }

    Vue.prototype.$imagePath = imagePath;
}
